import service from './baseService';
import { getCompanyId } from '../config';

export const getPurchasedTicketPassengersService = (dID) => {
    return service.get(`/ticket-passenger/purchasedTicketPassengers/${dID}`, { params: { companyId: getCompanyId() } });
};

export const checkInOutPassengerService = (id, data) => {
    return service.put(`/ticket-passenger/checkin/${id}`, data, {
        params: { companyID: getCompanyId() },
    });
};
export const printPurchasedTicketPassengersService = (dID) => {
    return service.get(`/ticket-passenger/printPurchasedTicketPassengers/${dID}`, {
        params: { companyId: getCompanyId() },
        responseType: 'arraybuffer',
    });
};
