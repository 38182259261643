import { createContext, useContext } from 'react';

export const MockupThemeContext = createContext();
export const AuthStateContext = createContext();
export const DispatchContext = createContext();
export const VehicleTypeContext = createContext();
export const VehicleSegmentContext = createContext();
export const VehicleInventoryContext = createContext();
export const YardsContext = createContext();
export const DriverNotificationsContext = createContext();
export const AccountsContext = createContext();
export const ProfilePageContext = createContext();
export const TicketPassengerContext = createContext();

export function useMockupThemeState() {
    const context = useContext(MockupThemeContext);
    if (context === undefined) {
        throw new Error('useMockupThemeState must be within an AuthProvider');
    }
    return context;
}

export function useAuthState() {
    const context = useContext(AuthStateContext);
    if (context === undefined) {
        throw new Error('useAuthState must be used within a AuthProvider');
    }

    return context;
}

export function useDispatchState() {
    const context = useContext(DispatchContext);
    if (context === undefined) {
        throw new Error('useDispatchState must be used within a AuthProvider');
    }

    return context;
}

export function useVehicleTypeState() {
    const context = useContext(VehicleTypeContext);
    if (context === undefined) {
        throw new Error('useVehicleTypeState must be within an AuthProvider');
    }
    return context;
}

export function useVehicleSegmentState() {
    const context = useContext(VehicleSegmentContext);
    if (context === undefined) {
        throw new Error('useVehicleSegment must be used within an AuthProvider');
    }
    return context;
}

export function useVehicleInventoryState() {
    const context = useContext(VehicleInventoryContext);
    if (context === undefined) {
        throw new Error('useVehicleInventoryState must be within an AuthProvider');
    }
    return context;
}

export function useYardsState() {
    const context = useContext(YardsContext);
    if (context === undefined) {
        throw new Error('useYardsState must be used within a AuthProvider');
    }

    return context;
}

export function useDriverNotificationsState() {
    const context = useContext(DriverNotificationsContext);
    if (context === undefined) {
        throw new Error('useDriverNotificationsState must be used within a AuthProvider');
    }

    return context;
}

export function useProfilePageState() {
    const context = useContext(ProfilePageContext);
    if (context === undefined) {
        throw new Error('useProfilePageState must be used within a AuthProvider');
    }

    return context;
}

export function useAccountState() {
    const context = useContext(AccountsContext);
    if (context === undefined) {
        throw new Error('useAccountState must be used within a AuthProvider');
    }
    return context;
}

export function useTicketPassengerState() {
    const context = useContext(TicketPassengerContext);
    if (context === undefined) {
        throw new Error('useTicketPassengerState must be within an AuthProvider');
    }
    return context;
}
