import service from './baseService';

export const getAllDriverNotifications = () => {
    return service.get(`/driver-notifications`);
};

export const getDriverNotification = (notificationId) => {
    return service.get(`/driver-notifications/${notificationId}`);
};

export const markNotificationAsRead = (notificationId) => {
    return service.patch(`/driver-notifications/${notificationId}`);
};
