import { listVehicleSegments } from '../../services/vehicleSegmentsService';

export async function getVehicleSegments(dispatch, segmentId) {
    try {
        // dispatch({ type: 'REQUEST_VEHICLE_SEGMENTS' });
        let response = await listVehicleSegments(segmentId);

        if (response.data) {
            dispatch({ type: 'VEHICLE_SEGMENTS_SUCCESS', payload: response.data });
            return response.data;
        }

        dispatch({
            type: 'VEHICLE_SEGMENTS_ERROR',
            error: response.data.errors[0],
        });
        return;
    } catch (error) {
        dispatch({ type: 'VEHICLE_SEGMENTS_ERROR', error: error });
    }
}
