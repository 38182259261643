import { calculatePagination } from '../../utils/helper';

export const initialStateDispatch = {
    dispatches: [],
    dispatchesCopy: [],
    yards: [],
    vehicleTypes: [],
    date: null,
    dispatchDetails: [],
    segments: [],
    loading: false,
    errorMessage: null,
    pageInfo: {},
    perPageLimit: 25,
    allDispatches: [],
    DriverAbsenceEvents: [],
    DriverAbsenceEventList: [],
    DriverAvailabilityEvents: [],
    OtherAssignments: [],
    otherAssignmentEvents: [],
    printBlob: null,
    otherAssignmentEventList: [],
    storedFilters: {},
};

export const DispatchReducer = (state = initialStateDispatch, action) => {
    switch (action.type) {
        case 'REQUEST_LIST_DISPATCHES':
            return {
                ...state,
                loading: true,
            };
        case 'DISPATCHES_LIST_SUCCESS':
            return {
                ...state,
                date: action.selectedDate,
                dispatches: action.payload.allDispatches,
                dispatchesCopy: action.payload.allDispatches,
                DriverAbsenceEventList: action.payload.DriverAbsenceEvents,
                otherAssignmentEventList: action.payload.otherAssignments,
                pageInfo: calculatePagination(action.payload, state.perPageLimit),
                loading: false,
            };
        case 'DISPATCHES_ALL_LIST_SUCCESS':
            return {
                ...state,
                allDispatches: action.payload.allDispatches,
                DriverAbsenceEvents: action.payload.DriverAbsenceEvents,
                DriverAvailabilityEvents: action.payload.DriverAvailabilityEvents,
                otherAssignmentEvents: action.payload.otherAssignmentEvents,
                loading: false,
            };
        case 'STORE_FILTER':
            return {
                ...state,
                storedFilters: action.payload,
            };
        case 'DISPATCHES_LIST_ERROR':
            return {
                ...state,
                errorMessage: action.payload,
                loading: false,
            };
        case 'PAGE_LIMIT_CHANGE':
            return {
                ...state,
                perPageLimit: action.payload,
                pageInfo: calculatePagination(state.dispatches, action.payload),
            };
        case 'DISPATCHES_PAGE_CHANGE':
            return {
                ...state,
                pageInfo: {
                    ...state.pageInfo,
                    first: action.payload * state.perPageLimit,
                    last: action.payload * state.perPageLimit + state.perPageLimit,
                    currrentPage: action.payload,
                },
            };
        case 'REQUEST_DISPATCHES_DETAILS':
            return {
                ...state,
                loading: true,
            };

        case 'SET_FILTERED_DISPATCHES':
            return {
                ...state,
                loading: false,
                dispatches: action.payload.filteredDispatches,
                yards: action.payload.yardsSearch,
                vehicleTypes: action.payload.vehicleTypeSearch,
                date: action.payload.date,
                pageInfo: calculatePagination(action.payload.filteredDispatches, state.perPageLimit),
            };

        case 'DRIVER_DISPATCHES_SUCCESS':
            return {
                ...state,
                dispatchDetails: action.payload,
                loading: false,
            };
        case 'DRIVER_DISPATCHES_ERROR':
            return {
                ...state,
                errorMessage: action.payload,
                loading: false,
            };
        case 'REQUEST_DISPATCH_EDIT':
            return {
                ...state,
                loading: true,
                errorMessage: null,
            };
        case 'DISPATCH_EDIT_SUCCESS': {
            let newDispatches = [];
            let tempDispatch = {};
            newDispatches = state.dispatches.map((dispatch) => {
                tempDispatch = { ...dispatch };
                if (dispatch.id === action.id) {
                    tempDispatch = {
                        ...dispatch,
                        ...action.updateData,
                    };
                }
                return tempDispatch;
            });
            return {
                ...state,
                loading: false,
                dispatches: newDispatches,
                // dispatchDetails: newDispatchDetails
            };
        }
        case 'DISPATCH_EDIT_ERROR':
            return {
                ...state,
                loading: false,
                errorMessage: action.error,
            };
        case 'REQUEST_GET_DISPATCH_PRINT':
            return {
                ...state,
                loading: true,
                errorMessage: null,
            };
        case 'GET_DISPATCH_PRINT_SUCCESS':
            return {
                ...state,
                loading: false,
                printBlob: action.payload,
            };
        case 'GET_DISPATCH_PRINT_ERROR':
            return {
                ...state,
                loading: false,
                errorMessage: action.error,
            };
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};
