import { getYardsService } from '../../services/yards';

export async function getYards(dispatch) {
    try {
        let response = await getYardsService();

        if (response.data) {
            dispatch({ type: 'GET_YARDS_SUCCESS', payload: response.data });
            return response.data;
        }

        dispatch({ type: 'GET_YARDS_ERROR', error: response.data.errors[0] });
        return;
    } catch (error) {
        dispatch({ type: 'GET_YARDS_ERROR', error: error });
    }
}
