import { getPurchasedTicketPassengersService } from '../../services/ticketPassengerService';

export async function getPurchasedTicketPassengers(dispatch, dId) {
    try {
        dispatch({ type: 'TICKETS_PASSENGERS_REQUEST' });
        let response = await getPurchasedTicketPassengersService(dId);

        if (response.data) {
            dispatch({ type: 'PURCHASED_TICKET_PASSENGERS', payload: response.data });
            return response.data;
        }

        dispatch({ type: 'TICKET_PASSENGERS_ERROR', error: response.data.errors[0] });
        return;
    } catch (error) {
        dispatch({ type: 'TICKET_PASSENGERS_ERROR', error: error });
    }
}
