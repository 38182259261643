import React from 'react';
import { Navbar, Row, Col, Image } from 'react-bootstrap';
import './style.scss';
import Loader from '../Loader';

const Footer = ({ fixed = true, loading, mainColor }) => {
    return !loading ? (
        <>
            <footer className={`footer ${fixed ? 'position-fixed' : 'position-relative'}`}>
                <Navbar bg="primary" variant="dark" style={{ backgroundColor: `${mainColor}` }}>
                    <Row className="my-3 w-100 align-items-center">
                        <Col xs={12} lg={6} md={6} sm={12}>
                            <div>
                                <a target="_blank" href="https://tbndrives.com/" rel="noopener noreferrer">
                                    <Image
                                        src={
                                            'https://storage.googleapis.com/the-bus-network-bucket/default/Large%20Powered%20By.png'
                                        }
                                        className="img-fluid footer-logo"
                                        style={{ width: '200px' }}
                                    />
                                </a>
                            </div>
                        </Col>
                        <Col xs={12} lg={6} md={6} sm={12} style={{ textAlign: 'end' }}>
                            <p className="copyright" style={{ fontSize: '16px' }}>
                                Copyright {new Date().getFullYear()} TBNDrives
                            </p>
                        </Col>
                    </Row>
                </Navbar>
            </footer>
        </>
    ) : (
        <Loader />
    );
};
export default Footer;
