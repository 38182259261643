export const initialProfilePage = {
    profileInfos: {},
    loading: false,
    errorMessage: null,
};

export const ProfilePageReducer = (state = initialProfilePage, action) => {
    switch (action.type) {
        case 'RESET_PROFILE_DATA':
            return {
                ...state,
                profileInfos: {},
            };
        case 'REQUEST_GET_PROFILE':
            return {
                ...state,
                loading: true,
            };
        case 'GET_PROFILE_SUCCESS':
            return {
                ...state,
                profileInfos: action.payload,
                loading: false,
            };
        case 'GET_PROFILE_ERROR':
            return {
                ...state,
                loading: false,
                errorMessage: action.error,
            };
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};
