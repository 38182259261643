import {
    getDriverService,
    loginService,
    logoService,
    sendForgetPasswordEmailService,
    updateDriverService,
    loginImageService,
} from '../../services/authServices';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure();

export async function loginUser(dispatch, loginPayload) {
    try {
        dispatch({ type: 'REQUEST_LOGIN' });
        let response = await loginService(loginPayload);
        // let data = await response.json();

        if (response.data) {
            dispatch({ type: 'LOGIN_SUCCESS', payload: response.data.user });
            localStorage.setItem('user', JSON.stringify(response.data.user));
            localStorage.setItem('token', JSON.stringify(response.data.token));
            localStorage.setItem('driverportal_version', response.headers.driverportal_version);
            return response.data;
        }

        dispatch({
            type: 'LOGIN_ERROR',
            error: response.data.errorMessage ? response.data.errorMessage : 'Error occured while login ',
        });
        return response.data.errorMessage ? response.data.errorMessage : 'Error occured while login ';
    } catch (error) {
        dispatch({
            type: 'LOGIN_ERROR',
            error: error.response.data.message ? error.response.data.message : 'Invalid Email or password!',
        });
    }
}

export async function getDriver(dispatch, data) {
    try {
        let response = await getDriverService(data);

        if (response.data) {
            dispatch({ type: 'GET_DRIVER_SUCCESS', payload: response.data });
            return response.data;
        }

        dispatch({ type: 'GET_DRIVER_ERROR', error: response.data.errors[0] });
    } catch (error) {
        dispatch({ type: 'GET_DRIVER_ERROR', error: error });
    }
}

export async function updateDriver(dispatch, data, driverId) {
    try {
        let response = await updateDriverService(data, driverId);

        if (response.data) {
            dispatch({ type: 'UPDATE_DRIVER_SUCCESS', payload: response.data });
            return response.data;
        }

        dispatch({ type: 'UPDATE_DRIVER_ERROR', error: response.data.errors[0] });
        return;
    } catch (error) {
        dispatch({ type: 'UPDATE_DRIVER_ERROR', error: error });
    }
}

export async function sendForgetPasswordEmail(dispatch, payload) {
    try {
        let response = await sendForgetPasswordEmailService(payload);
        if (response.data) {
            if (response.data[0].statusCode === 200 || response.data[0].statusCode === 202) {
                dispatch({ type: 'SEND_EMAIL_SUCCESS', payload: response.data });
                return;
            }
        }
        dispatch({ type: 'SEND_EMAIL_ERROR', error: response.data.errors[0] });
        return;
    } catch (error) {
        dispatch({ type: 'SEND_EMAIL_ERROR', error: error });
    }
}

export async function getCompanyLogo(dispatch, companyID) {
    try {
        dispatch({ type: 'REQUEST_LOGO' });
        let response = await logoService(companyID);
        if (response.data) {
            dispatch({ type: 'GET_LOGO_SUCCESS', payload: response.data });
            return response.data;
        }

        dispatch({ type: 'GET_LOGO_ERROR', error: response.data.errors[0] });
        return;
    } catch (error) {
        dispatch({ type: 'GET_LOGO_ERROR', error: error });
    }
}

export async function getLoginImage(dispatch) {
    try {
        let response = await loginImageService();
        if (response.data) {
            dispatch({ type: 'GET_LOGIN_IMAGE_SUCCESS', payload: response.data });
            return response.data;
        }

        dispatch({ type: 'GET_LOGIN_IMAGE_ERROR', error: response.data.errors[0] });
        return;
    } catch (error) {
        dispatch({ type: 'GET_LOGIN_IMAGE_ERROR', error: error });
    }
}
