import React, { useEffect, useState } from 'react';
import { Navbar } from 'react-bootstrap';
import './style.scss';
import { useHistory } from 'react-router-dom';
import { getCompanyLogo, updateDriver } from '../../Context/actions/authActions';
import { checkPassword } from '../../services/authServices';
import { useAuthState, useAccountState, getTripSettings } from '../../Context';
import UpdatePasswordModal from '../UpdatePasswordModal';
import { toast } from 'react-toastify';

function UserOptions({ handlePasswordToggleModal, handleLogout }) {
    return (
        <>
            <a className="dropdown-item" href="#" onClick={() => handlePasswordToggleModal(true)}>
                <i className="fas fa-lock mr-2" />
                Change Password
            </a>
            <a className="dropdown-item" href="#" onClick={handleLogout}>
                <i className="fas fa-sign-out-alt mr-2" />
                Logout
            </a>
        </>
    );
}

const MockupHeader = ({ menuItems = [] }) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const [togglePasswordModal, handlePasswordToggleModal] = useState(false);
    const [userAvatarOpen, setUserAvatarOpen] = useState(false);
    const [activeMenuCount, setActiveMenuCount] = useState(
        menuItems.reduce((count, item) => ({ ...count, [item.name]: 0 }), {})
    );
    const { authDispatch } = useAuthState();
    const {
        AccountsDispatch,
        tripSettings: { tripSettings },
    } = useAccountState();
    const history = useHistory();

    useEffect(() => {
        (async () => {
            if (user?.companyID) {
                await getTripSettings(AccountsDispatch);
                await getCompanyLogo(authDispatch, user.companyID);
            }
        })();
        return () => {};
    }, [AccountsDispatch, authDispatch, user?.companyID]);

    // hook that set count and set state for active menu items in each menu
    useEffect(() => {
        let count = 0,
            activeCount = { ...activeMenuCount };
        for (let menuItem of menuItems) {
            count = 0;
            if (menuItem.children.length > 0) {
                // eslint-disable-next-line no-loop-func
                menuItem.children.forEach((subMenu) => {
                    if (subMenu.children.length > 0) {
                        // menu with peta(sub-sub) menus
                        subMenu.children.forEach(() => {
                            count++;
                        });
                    } else {
                        // menu with sub menus
                        count++;
                    }
                });
            }
            activeCount = {
                ...activeCount,
                [menuItem.name]: count,
            };
        }
        setActiveMenuCount(activeCount);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [menuItems]);

    const handleUpdatePassword = async (data) => {
        try {
            await updateDriver(authDispatch, data, user.id);
            toast.success('Updated Password Successfully');
        } catch (e) {
            toast.error('Update Password Failed');
        }
        handlePasswordToggleModal(false);
    };

    const handleLogout = async () => {
        await localStorage.clear();
        history.push('/login');
    };

    return (
        <div className="headerDiv" style={{ backgroundColor: `${tripSettings?.mainColor}` }}>
            <Navbar
                bg="inherit"
                expand="xlg"
                variant="inherit"
                className="navbar navbar-expand-xlg p-0"
                style={{ backgroundColor: `${tripSettings?.mainColor}` }}
            >
                <div className="ml-2 ml-lg-0 rightSide">
                    <div
                        className={`dropdown px-lg-2 py-2 px-4 ${userAvatarOpen ? 'show' : ''}`}
                        id="avatar"
                        style={{ backgroundColor: `${tripSettings.mainColor}` }}
                    >
                        <a
                            className={`dropdown-toggle justify-content-start align-items-center d-flex text-decoration-none`}
                            href="#"
                            id="user"
                            role="button"
                            aria-haspopup="true"
                            aria-expanded={userAvatarOpen}
                            onClick={() => {
                                setUserAvatarOpen(!userAvatarOpen);
                            }}
                        >
                            Welcome{' '}
                            <span
                                className="font-weight-bold text-uppercase ml-2 adminName"
                                style={{ color: `${tripSettings.textColor}` }}
                            >
                                {user ? `${user.firstName} ${user.lastName}` : ''}
                            </span>
                            <span
                                className="justify-content-center align-items-center d-flex rounded-circle ml-3 mr-2"
                                style={{ color: '#000' }}
                            >
                                {user && user.firstName[0].toUpperCase()}
                            </span>
                        </a>
                        <div
                            className={`dropdown-menu dropdown-menu-right p-0 m-0 ${userAvatarOpen ? 'show' : ''}`}
                            style={{ backgroundColor: `${tripSettings.mainColor}` }}
                            aria-labelledby="user"
                        >
                            <UserOptions
                                handlePasswordToggleModal={handlePasswordToggleModal}
                                handleLogout={handleLogout}
                            />
                        </div>
                    </div>
                </div>
                {
                    // modal that triggers to change password
                    togglePasswordModal ? (
                        <UpdatePasswordModal
                            showCurrent={true}
                            passwordCheck={checkPassword}
                            user={user}
                            updatePassword={handleUpdatePassword}
                            handlePasswordToggleModal={handlePasswordToggleModal}
                        />
                    ) : (
                        ''
                    )
                }
            </Navbar>
        </div>
    );
};

export default MockupHeader;
