import React from 'react';
import { Image } from 'react-bootstrap';
import './style.scss';
import Loader from '../Loader';
import { useAuthState } from '../../Context';

const MobileFooter = ({ fixed = true, loading }) => {
    const {
        authUser: { logo },
    } = useAuthState();

    return !loading ? (
        <>
            <footer className={`footer ${fixed ? 'position-fixed' : 'position-relative'}`}>
                <div className="d-flex justify-content-between" style={{ textAlign: 'center', background: '#f4f5f4' }}>
                    <a className="navbar-brand d-flex align-items-center justify-content-center bg-white mr-0" href="#">
                        <Image src={logo} className="m-0 p-0 logo" style={{ width: '50px' }} />
                    </a>
                    <a target="_blank" href="https://tbndrives.com/" rel="noopener noreferrer">
                        <Image
                            src={'https://tbndrives.com/wp-content/uploads/2022/01/180x180.png'}
                            className="img-fluid footer-logo m-2"
                            style={{ width: '50px' }}
                        />
                    </a>
                </div>
            </footer>
        </>
    ) : (
        <Loader />
    );
};
export default MobileFooter;
