export const initialStateVehicleSegment = {
    segments: [],
    loading: false,
    errorMessage: null,
};

export const VehicleSegmentReducer = (state = initialStateVehicleSegment, action) => {
    switch (action.type) {
        case 'REQUEST_VEHICLE_SEGMENTS':
            return {
                ...state,
                loading: true,
            };
        case 'VEHICLE_SEGMENTS_SUCCESS':
            return {
                ...state,
                segments: action.payload,
                loading: false,
            };
        case 'VEHICLE_SEGMENTS_ERROR':
            return {
                ...state,
                loading: false,
                errorMessage: action.error,
            };
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};
