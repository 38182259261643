import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { useDispatchState, useAccountState, getTripSettings } from '../../../../Context';
import { getAllDispatch, getAllDispatchEvents } from '../../../../Context/actions/dispatchActions';
import './index.scss';
import * as dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { Col, Row } from 'react-bootstrap';
import moment from 'moment';
import SimpleTable from '../../../../Components/SimpleTable';
dayjs.extend(utc);
dayjs.extend(timezone);

const Dispatches = (props) => {
    const {
        dispatchDispatch,
        dispatch: {
            dispatches,
            dispatchesCopy,
            DriverAbsenceEventList,
            DriverAbsenceEvents,
            otherAssignmentEvents,
            otherAssignmentEventList,
            date,
        },
    } = useDispatchState();
    const { AccountsDispatch } = useAccountState();
    const [loading, setLoading] = useState(false);
    const [updatedDispatches, setUpdatedDispatches] = useState([]);
    const [warningDispatches, setWarningDispatches] = useState([]);
    const [dispatchEvents, setDispatchEvents] = useState([]);
    const calendarRef = useRef();
    const initialDate = date ? date : new Date();

    useEffect(() => {
        (async () => {
            setLoading(true);
            let accSetting = await getTripSettings(AccountsDispatch);
            const root = document.documentElement;
            root?.style.setProperty('--background-color-custom', accSetting.sectionColor);
            root?.style.setProperty('--background-color-button', accSetting.buttonColor);

            const dispatches = await getAllDispatch(dispatchDispatch, date ? date : moment());
            const dispatchesWithoutStartOrEnd = dispatches?.allDispatches?.filter(
                (d) => !d.driverStartTime || !d.driverEndTime
            );
            if (dispatchesWithoutStartOrEnd?.length) setWarningDispatches(dispatchesWithoutStartOrEnd);

            const { allDispatches } = await getAllDispatchEvents(dispatchDispatch);
            // calendar requires each event to have a unique ID
            setDispatchEvents(
                allDispatches.map((dispatch, i) => ({
                    ...dispatch,
                    id: dispatch.id + '-' + i,
                }))
            );

            setLoading(false);
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatchDispatch]);

    const checkTime = useCallback(() => {
        let updatedDispatch = dispatches
            .filter((d) => d.driverStartTime && d.driverEndTime)
            .map((item) => {
                let difference = moment(item.driverStartTime).diff(moment(), 'minutes');
                if (!item.checkInButtonDisableMin) {
                    return { ...item, isButtonDisabled: false };
                } else if (difference < Number(item.checkInButtonDisableMin)) {
                    return { ...item, isButtonDisabled: false };
                } else {
                    return { ...item, isButtonDisabled: true };
                }
            });
        setUpdatedDispatches(updatedDispatch);
    }, [dispatches]);

    useEffect(() => {
        checkTime();
        const interval = setInterval(checkTime, 60000);
        return () => {
            clearInterval(interval);
        };
    }, [checkTime, dispatches]);

    if (!loading && dispatchesCopy.length > 0) {
        dispatchesCopy.reduce((types, dispatch) => {
            if (dispatch.baseOfOperation && !types.includes(dispatch.baseOfOperation)) {
                types.push(dispatch.baseOfOperation);
            }
            return types;
        }, []);
    }

    if (!loading && dispatchesCopy.length > 0) {
        dispatchesCopy.reduce((types, dispatch) => {
            if (dispatch.vehicleDisplayName && !types.includes(dispatch.vehicleDisplayName)) {
                types.push(dispatch.vehicleDisplayName);
            }
            return types;
        }, []);
    }

    const renderTime = (item, key) => {
        return moment(item[key], 'MM-DD-YYYY HH:mm').format('MM/DD/YYYY HH:mm');
    };

    const onRowClick = (item) => {
        props.history.push(`/driver/assignments/${item.id}`);
    };

    const renderDriverConfirmed = (item) => {
        let content = (
            <ButtonGroup>
                <Button
                    variant="success"
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    className="bg-success text-white"
                >
                    <i className="fas fa-check-circle" />
                </Button>
                <Button
                    variant="danger"
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    className="bg-danger text-white"
                >
                    <i className="fas fa-times-circle" />
                </Button>
            </ButtonGroup>
        );
        if (Number(item.hideDriverPortalDeclineButton))
            content = (
                <ButtonGroup>
                    <Button
                        variant="success"
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                        className="bg-success text-white"
                    >
                        <i className="fas fa-check-circle" />
                    </Button>
                </ButtonGroup>
            );
        if (item.driverAcceptedAt) {
            content = (
                <Button variant="success" disabled={true} className="bg-success text-white">
                    <i className="fas fa-check-circle" />
                </Button>
            );
        }
        if (item.driverDeclinedAt) {
            content = (
                <Button variant="danger" disabled={true} className="bg-danger text-white">
                    <i className="fas fa-times-circle" />
                </Button>
            );
        }
        return content;
    };

    // Handles when a user clicks on a calendar day
    const handleEventClick = async (e, isNumberClick = false) => {
        const calendarApi = calendarRef.current.getApi();
        const date = isNumberClick ? moment(e.date) : moment(e.event?.start);

        const elems = document.querySelectorAll('[role="gridcell"]');
        for (let elem of elems) {
            elem.style.backgroundColor = '#fff';
        }

        const fdate = date.format('YYYY-MM-DD');
        document.querySelector(`[data-date="${fdate}"]`).style.backgroundColor = '#8bc34a';

        calendarApi.gotoDate(date.toISOString());

        window.scrollTo(0, 0);
        setLoading(true);
        await getAllDispatch(dispatchDispatch, date);
        setLoading(false);
    };

    const fieldNames = [
        ['bookingId', 'Booking ID'],
        ['operationalContactName', 'Contact Name'],
        ['operationalContactOrganization', 'Organization'],
        ['vehicleDisplayName', 'Vehicle Type'],
        ['vehicleId', 'Vehicle ID'],
        ['driverStartTime', 'Driver Start Time', (item) => renderTime(item, 'driverStartTime')],
        ['firstDepartureTime', 'First Pick-Up Time', (item) => renderTime(item, 'firstDepartureTime')],
        ['firstPickupLocation', 'First Pick-Up Location'],
        ['lastArrivalTime', 'Last Drop-Off Time', (item) => renderTime(item, 'lastArrivalTime')],
        ['driverEndTime', 'Driver End Time', (item) => renderTime(item, 'driverEndTime')],
        ['baseOfOperation', 'Base of Operation'],
        ['driverConfirmed', 'Accepted', renderDriverConfirmed],
    ];

    const warningDispatches_fieldNames = [
        ['company', 'Company'],
        ['bookingFormattedID', 'Booking ID'],
        ['firstDepartureTime', 'First Departure Time'],
    ];

    const DetailCard = ({ detail }) => {
        return (
            <div className="d-flex list-div mt-2" onClick={onRowClick.bind({}, detail)}>
                <div className="col-6">
                    <div className={detail.driverAcceptedAt ? `color-line green` : `color-line red`}></div>
                    <b>
                        {detail.bookingId
                            ? `Booking  ${detail.bookingFormattedID}`
                            : detail.scheduleServiceDescription
                            ? detail.scheduleServiceDescription
                            : ''}
                    </b>
                    <div className="card-l">
                        <span className="card-lt">{detail.operationalContactOrganization}</span>
                        <span className="card-lb">{detail.baseOfOperation}</span>
                    </div>
                </div>
                <div className="col-6 text-right mt-auto pl-2">
                    <div className="card-r">
                        {detail.routeDescription && (
                            <span className="card-rt">
                                <span>{detail.routeDescription}</span>
                            </span>
                        )}
                        <span className="card-rt">
                            <span className="ttl">start</span> {detail.driverStartTime}
                        </span>
                        <span className="card-rb">
                            <span className="ttl">end</span> {detail.driverEndTime}
                        </span>
                    </div>
                </div>
            </div>
        );
    };

    const DetailAbsenceCard = ({ detail }) => {
        return (
            <div className="d-flex list-div mt-2">
                <div
                    className="col-6"
                    style={{
                        display: 'flex',
                        'align-items': 'center',
                    }}
                >
                    <div className="card-l align-self-center">
                        <span className="card-lt">{detail.absenceType}</span>
                    </div>
                </div>
                <div className="col-6 text-right pl-2">
                    <div className="card-r">
                        <span className="card-rt">
                            <span className="ttl">start</span> {detail.startTime}
                        </span>
                        <span className="card-rb">
                            <span className="ttl">end</span> {detail.endtime}
                        </span>
                    </div>
                </div>
            </div>
        );
    };

    const OtherAssignmentCard = ({ detail }) => {
        return (
            <div className="d-flex list-div mt-2">
                <div
                    className="col-6"
                    style={{
                        display: 'flex',
                        'align-items': 'center',
                    }}
                >
                    <div className="card-l align-self-center">
                        <span className="card-lt">{detail.assignmentType}</span>
                    </div>
                </div>
                <div className="col-6 text-right pl-2">
                    <div className="card-r">
                        <span className="card-rt">
                            <span className="ttl">start</span> {detail.scheduledStartTime}
                        </span>
                        <span className="card-rb">
                            <span className="ttl">end</span> {detail.scheduledEndTime}
                        </span>
                    </div>
                </div>
            </div>
        );
    };

    if (dispatches.length && dispatches[0]) {
        if (dispatches[0].company) {
            fieldNames.unshift(['company', 'Company']);
        }
        if (dispatches[0].parentCompany) {
            fieldNames.unshift(['parentCompany', 'Parent Company']);
        }
    }

    return (
        <div className="w-100 mb-3 mt-1 content-section" style={{ minHeight: 'auto' }}>
            <Row className="mx-2">
                <Col xs={12} className="px-0">
                    <div className="calendar-wrapper-2">
                        <FullCalendar
                            ref={calendarRef}
                            plugins={[dayGridPlugin, interactionPlugin]}
                            initialView="dayGridMonth"
                            selectable={true}
                            editable={true}
                            headerToolbar={{
                                left: 'prev',
                                center: 'title',
                                right: 'next',
                            }}
                            initialDate={initialDate}
                            events={[...dispatchEvents, ...DriverAbsenceEvents, ...otherAssignmentEvents]}
                            eventClick={(e) => handleEventClick(e)}
                            dayHeaderFormat={{ weekday: 'long' }}
                            height="420px"
                            dateClick={(e) => {
                                handleEventClick(e, true);
                            }}
                        />
                    </div>
                </Col>
                {warningDispatches?.length ? (
                    <div className="my-4">
                        <p className="text-danger small bold">
                            You are assigned to the following dispatch(es) which do not have a driver start or end time
                            set. Please contact your dispatcher.
                        </p>
                        <SimpleTable
                            loading={loading}
                            fieldNames={warningDispatches_fieldNames}
                            data={warningDispatches}
                        />
                    </div>
                ) : (
                    ''
                )}
                <Col xs={12} className="px-0 mt-2">
                    {updatedDispatches
                        .sort((a, b) => new Date(a.driverStartTime) - new Date(b.driverStartTime))
                        .map((data) => (
                            <DetailCard key={data.id} detail={data} />
                        ))}
                </Col>
                {DriverAbsenceEventList.length ? (
                    <span className="mt-2" style={{ 'font-weight': '600', 'margin-bottom': '-12px' }}>
                        Driver Absence Events
                    </span>
                ) : (
                    ''
                )}
                <Col xs={12} className="px-0 mt-2">
                    {DriverAbsenceEventList.map((data, i) => (
                        <DetailAbsenceCard detail={data} key={i} />
                    ))}
                </Col>

                {otherAssignmentEventList.length ? (
                    <span className="mt-2" style={{ 'font-weight': '600', 'margin-bottom': '-12px' }}>
                        Other Assignment Events
                    </span>
                ) : (
                    ''
                )}
                <Col xs={12} className="px-0 mt-2">
                    {otherAssignmentEventList.map((data, i) => (
                        <OtherAssignmentCard detail={data} key={i} />
                    ))}
                </Col>
            </Row>
        </div>
    );
};

export default Dispatches;
