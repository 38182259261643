export const initialStateVehicleInventory = {
    inventory: [],
    loading: false,
    errorMessage: null,
};

export const VehicleInventoryReducer = (state = initialStateVehicleInventory, action) => {
    switch (action.type) {
        case 'REQUEST_LIST_VEHICLE_INVENTORY':
            return {
                ...state,
                loading: true,
            };
        case 'LIST_VEHICLE_INVENTORY_SUCCESS':
            return {
                ...state,
                inventory: action.payload,
                loading: false,
            };
        case 'LIST_VEHICLE_INVENTORY_ERROR':
            return {
                ...state,
                errorMessage: action.payload,
                loading: false,
            };
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};
