import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import './index.scss';

const Dialog = ({
    title,
    message,
    okButtonText = 'OK',
    cancelButtonText = 'Cancel',
    displayOkButton = true,
    onCloseClick = () => {},
    displayCancelButton = false,
    okButtonClick = () => {},
    cancelButtonClick = () => {},
}) => {
    return (
        <Modal className="warning-modal" animation={false} size="md" show={true} onHide={onCloseClick}>
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
                <i className="fas fa-times closeButton" onClick={onCloseClick} />
            </Modal.Header>
            <Modal.Body>
                <p>{message}</p>
            </Modal.Body>
            <Modal.Footer>
                {displayOkButton && (
                    <Button id="warning" variant="success" className="btn w-25 pt-2 pb-2" onClick={okButtonClick}>
                        {okButtonText}
                    </Button>
                )}
                {displayCancelButton && (
                    <Button id="warning" variant="danger" className="btn w-25 pt-2 pb-2" onClick={cancelButtonClick}>
                        {cancelButtonText}
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default Dialog;
