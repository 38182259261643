import React, { useLayoutEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import Footer from '../../Components/Footer';
import MobileFooter from '../../Components/mobileFooter';
import Header from '../../Components/MockupHeader/index';
import './Mockup.scss';
import { setMockupTheme, useMockupThemeState, useAccountState, getTripSettings } from '../../Context';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useHistory } from 'react-router-dom';
import MobileHeader from '../../Components/MobileMockupHeader/index';

// Variable Used
// 1)  name:- primaryTextColor,
//     usedFor:- linkText, headerIcons
//
// 2)  name:- backgroundColor,
//     usedFor:- backgroundColor for card-header, accordion-header
//
// 3)  name:- fontColor,
//     usedFor:- textColor used in conjunction with backgroundColor

const Mockup = ({ children, mobileAndTabletCheck }) => {
    const {
        mockupThemeDispatch,
        mockupTheme: { backgroundColor, fontColor, primaryTextColor, logo, footerLogo },
    } = useMockupThemeState();
    const {
        AccountsDispatch,
        tripSettings: { tripSettings },
    } = useAccountState();
    const [loading, setLoading] = useState(false);
    const navigate = useHistory();

    useLayoutEffect(() => {
        (async function () {
            setLoading(true);
            await getTripSettings(AccountsDispatch);
            await setMockupTheme(mockupThemeDispatch);
            setLoading(false);
        })();
    }, [AccountsDispatch, mockupThemeDispatch]);

    const MenuItems = [
        {
            name: 'Assignments',
            children: [],
            icon: 'fa-calendar',
            active: true,
            webUrl: '/driver/assignments',
            path: '/driver/assignments',
            isEnabled: true,
            uuid: '9f8db025-bec6-45a7-8c9e-f58c7e12662b',
        },
        {
            name: 'Messages',
            children: [],
            icon: 'fa-comment ',
            active: true,
            webUrl: '/driver/notifications',
            path: '/driver/notifications',
            isEnabled: true,
            uuid: '3109779d-00de-4e51-8ea3-f92e3935f41a',
        },
        {
            name: 'Profile',
            children: [],
            icon: 'fa-user ',
            active: true,
            webUrl: '/driver/profile',
            path: '/driver/profile',
            isEnabled: true,
            uuid: '9bf95200-613b-40d9-a130-ec1fe779b6e5',
        },
    ];
    return (
        <>
            <div>
                <style
                    dangerouslySetInnerHTML={{
                        __html: `
                    :root {
                        --themeBackgroundColor: ${backgroundColor};
                        --themeFontColor: ${fontColor};
                        --primaryTextColor: ${primaryTextColor};
                    }
                `,
                    }}
                />
                <Container fluid className="p-0 fullHeight">
                    {!mobileAndTabletCheck ? (
                        <Header menuItems={MenuItems} logo={logo} />
                    ) : (
                        <MobileHeader menuItems={MenuItems} logo={logo} />
                    )}

                    {mobileAndTabletCheck && (
                        <Tabs defaultActiveKey="Calendar" id="uncontrolled-tab-example">
                            <Tab
                                eventKey="Calendar"
                                title="Calendar"
                                onEnter={() => {
                                    navigate.push(`/driver/assignments`);
                                }}
                            ></Tab>
                            <Tab
                                eventKey="Messages"
                                title="Messages"
                                onEnter={() => {
                                    navigate.push(`/driver/notifications`);
                                }}
                            ></Tab>
                            <Tab
                                eventKey="Profile"
                                title="Profile"
                                onEnter={() => {
                                    navigate.push(`/driver/profile`);
                                }}
                            ></Tab>
                        </Tabs>
                    )}
                    {children}
                    {!mobileAndTabletCheck ? (
                        <Footer fixed={false} logo={footerLogo} loading={loading} mainColor={tripSettings?.mainColor} />
                    ) : (
                        <MobileFooter
                            fixed={true}
                            logo={footerLogo}
                            loading={loading}
                            mainColor={tripSettings?.mainColor}
                        />
                    )}
                </Container>
            </div>
        </>
    );
};

export default Mockup;
