import service from './baseService';
import { getUserId } from '../config';

export const listDispatch = (date = '') => {
    let params = { driverReferenceId: getUserId() };
    if (date) params.selectedDate = date.format('YYYY-MM-DD');
    return service.get(`/driver-dispatch`, { params });
};

export const dispatchDetails = (dispatchId) => {
    return service.get(`/driver-dispatch/${dispatchId}`);
};

export const updateDispatchDetailsService = (dispatchId, payload) => {
    return service.put(`/driver-dispatch/${dispatchId}`, payload);
};

export const listAllDispatch = () => {
    let params = { driverReferenceId: getUserId() };
    return service.get(`/driver-dispatch/all-dispatch`, { params });
};

export const printDispatchService = (dispatchID) => {
    return service.post(
        `/driver-dispatch/get-work-tickets-print/${dispatchID}`,
        {},
        {
            responseType: 'arraybuffer',
        }
    );
};
