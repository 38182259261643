import { calculatePagination } from '../../utils/helper';

export const initialStateNotification = {
    notifications: [],
    notificationsCopy: [],
    notificationDetails: {},
    loading: false,
    errorMessage: null,
    pageInfo: {},
    perPageLimit: 25,
};

export const NotificationReducer = (state = initialStateNotification, action) => {
    switch (action.type) {
        case 'REQUEST_LIST_NOTIFICATIONS':
            return {
                ...state,
                loading: true,
            };
        case 'NOTIFICATIONS_LIST_SUCCESS':
            return {
                ...state,
                notifications: action.payload,
                notificationsCopy: action.payload,
                pageInfo: calculatePagination(action.payload, state.perPageLimit),
                loading: false,
            };
        case 'NOTIFICATIONS_LIST_ERROR':
            return {
                ...state,
                errorMessage: action.payload,
                loading: false,
            };
        case 'PAGE_LIMIT_CHANGE':
            return {
                ...state,
                perPageLimit: action.payload,
                pageInfo: calculatePagination(state.notifications, action.payload),
            };
        case 'NOTIFICATIONS_PAGE_CHANGE':
            return {
                ...state,
                pageInfo: {
                    ...state.pageInfo,
                    first: action.payload * state.perPageLimit,
                    last: action.payload * state.perPageLimit + state.perPageLimit,
                    currrentPage: action.payload,
                },
            };
        case 'REQUEST_NOTIFICATIONS_DETAILS':
            return {
                ...state,
                notificationDetails: initialStateNotification.notificationDetails,
                loading: true,
            };
        case 'DRIVER_NOTIFICATIONS_SUCCESS':
            return {
                ...state,
                notificationDetails: action.payload,
                loading: false,
            };
        case 'DRIVER_NOTIFICATIONS_ERROR':
            return {
                ...state,
                errorMessage: action.payload,
                loading: false,
            };
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};
