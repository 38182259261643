import React from 'react';
import classes from './FilterDropdown.module.scss';
import { Dropdown } from 'react-bootstrap';

// options: [{ title, value }]
const FilterDropdown = ({
    title,
    options,
    changeHandler,
    dropdownId = '',
    filterValues = [],
    titleKeyName = 'title',
}) => {
    let selectedFirstOption = options.findIndex((opt) => filterValues.includes(opt.value));
    return (
        <Dropdown as="div" className={`mx-1 p-2 ${selectedFirstOption > -1 ? classes.highlight : ''}`}>
            <Dropdown.Toggle
                as="span"
                role={'button'}
                variant="transperent"
                className={`"mx-1 p-2" ${selectedFirstOption > -1 ? classes.highlight : ''}`}
                id={dropdownId}
            >
                {title}
            </Dropdown.Toggle>
            <Dropdown.Menu
                className="m-0 p-0"
                aria-labelledby={dropdownId}
                style={{ overflow: 'auto', maxHeight: 'calc(100vh - 150px)' }}
            >
                {options.map((option, index) => (
                    <Dropdown.Item
                        className={`text-decoration-none ${classes.item} ${
                            filterValues.includes(option.value) ? classes.active : ''
                        }`}
                        key={index}
                        as="span"
                        onClick={() => changeHandler(option)}
                    >
                        {option[titleKeyName]}
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default FilterDropdown;
