export const initialStateTicketPassenger = {
    ticketPassengerDetails: {},
    loading: false,
    errorMessage: null,
    purchasedTicketPassengers: [],
};

export const TicketPassengerReducer = (state = initialStateTicketPassenger, action) => {
    switch (action.type) {
        case 'TICKETS_PASSENGERS_REQUEST':
            return {
                ...state,
                loading: true,
            };
        case 'GET_TICKET_PASSENGERS_DETAILS_SUCCESS':
            return {
                ...state,
                ticketPassengerDetails: action.payload,
                loading: false,
            };
        case 'PURCHASED_TICKET_PASSENGERS':
            return {
                ...state,
                purchasedTicketPassengers: action.payload,
                loading: false,
            };
        case 'TICKET_PASSENGERS_ERROR':
            return {
                ...state,
                loading: false,
                errorMessage: action.error,
            };

        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};
