import {
    dispatchDetails,
    listDispatch,
    updateDispatchDetailsService,
    listAllDispatch,
    printDispatchService,
} from '../../services/dispatchService';

export async function getAllDispatch(dispatch, date = '') {
    try {
        dispatch({ type: 'REQUEST_LIST_DISPATCHES' });
        let response = await listDispatch(date);
        if (response.data) {
            dispatch({
                type: 'DISPATCHES_LIST_SUCCESS',
                payload: response.data,
                selectedDate: date.toDate(),
            });
            return response.data;
        }
        dispatch({ type: 'DISPATCHES_LIST_ERROR', error: response.data.errors[0] });
        return;
    } catch (error) {
        dispatch({ type: 'DISPATCHES_LIST_ERROR', error: error });
    }
}

export async function getAllDispatchEvents(dispatch, date = '') {
    try {
        dispatch({ type: 'REQUEST_LIST_DISPATCHES' });
        let response = await listAllDispatch(date);
        if (response.data) {
            dispatch({ type: 'DISPATCHES_ALL_LIST_SUCCESS', payload: response.data });
            return response.data;
        }
        dispatch({ type: 'DISPATCHES_LIST_ERROR', error: response.data.errors[0] });
        return;
    } catch (error) {
        dispatch({ type: 'DISPATCHES_LIST_ERROR', error: error });
    }
}

export async function getDispatchDetails(dispatch, driverId) {
    try {
        dispatch({ type: 'REQUEST_DISPATCHES_DETAILS' });
        let response = await dispatchDetails(driverId);

        if (response.data) {
            dispatch({ type: 'DRIVER_DISPATCHES_SUCCESS', payload: response.data });
            return response.data;
        }

        dispatch({
            type: 'DRIVER_DISPATCHES_ERROR',
            error: response.data.errors[0],
        });
        return;
    } catch (error) {
        dispatch({ type: 'DRIVER_DISPATCHES_ERROR', error: error });
    }
}

export function storeFilters(dispatch, filtersObj = {}) {
    console.log(filtersObj);
    if (Object.keys(filtersObj)?.length) dispatch({ type: 'STORE_FILTER', payload: filtersObj });
    else dispatch({ type: 'STORE_FILTER', payload: {} });
}

export function filterDispatchesByType(dispatch, dispatches, yardsSearch, vehicleTypeSearch, date) {
    dispatch({ type: 'REQUEST_LIST_DISPATCHES' });
    let filteredDispatches = dispatches;
    if (yardsSearch.length) {
        filteredDispatches = filteredDispatches.filter((dispatch) => yardsSearch.includes(dispatch.baseOfOperation));
    }
    if (vehicleTypeSearch.length) {
        filteredDispatches = filteredDispatches.filter((dispatch) =>
            vehicleTypeSearch.includes(dispatch.vehicleDisplayName)
        );
    }

    dispatch({
        type: 'SET_FILTERED_DISPATCHES',
        payload: { filteredDispatches, yardsSearch, vehicleTypeSearch, date },
    });
}

export async function updateDispatchDetails(dispatch, dispatchId, payload) {
    dispatch({ type: 'REQUEST_DISPATCH_EDIT' });
    try {
        let response = await updateDispatchDetailsService(dispatchId, payload);

        if (response.data) {
            dispatch({
                type: 'DISPATCH_EDIT_SUCCESS',
                payload: response.data,
                updateData: payload,
                id: dispatchId,
            });
            return response.data;
        }

        dispatch({ type: 'DISPATCH_EDIT_ERROR', error: response.data.errors[0] });
    } catch (error) {
        dispatch({ type: 'DISPATCH_EDIT_ERROR', error: error });
    }
}

export async function printDispatch(dispatch, dispatchID) {
    try {
        let response = await printDispatchService(dispatchID);

        if (response.data) {
            dispatch({ type: 'REQUEST_GET_DISPATCH_PRINT', payload: response.data });
            return response.data;
        }

        dispatch({
            type: 'GET_DISPATCH_PRINT_SUCCESS',
            error: response.data.errors[0],
        });
    } catch (e) {
        dispatch({ type: 'GET_DISPATCH_PRINT_ERROR', error: e?.message });
    }
}
