import { Modal, Spinner } from 'react-bootstrap';

const Loader = ({ onHide = () => {}, show = true, animation = false }) => {
    return (
        <Modal onHide={onHide} show={show} animation={animation} className="loading-modal text-center">
            <Spinner animation="border" variant="primary" />
        </Modal>
    );
};

export default Loader;
