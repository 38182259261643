import React from 'react';
import { Col, Row } from 'react-bootstrap';
import './style.module.scss';

const DetailsPageLayout = ({ title, icon, headerActions = null, children, textColor }) => {
    return (
        <div className="w-100 mb-3">
            <Row className="mx-3">
                <Col xs={12}>
                    <div className="d-flex justify-content-between w-100 mt-3 mb-3 flex-column flex-md-row">
                        <h2 style={{ color: `${textColor}` }}>
                            <i className={`fas ${icon}`} style={{ color: `${textColor}` }} /> {title}
                        </h2>
                        <div>{headerActions}</div>
                    </div>
                </Col>
            </Row>

            <Row xs={12} style={{ maxWidth: '100%' }} className="mx-sm-3">
                <Col xs={12} style={{ minHeight: 'calc(100vh - 323px)' }} className="pr-0">
                    {children}
                </Col>
            </Row>
        </div>
    );
};

export default DetailsPageLayout;
