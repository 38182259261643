import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import {
    getAllNotifications,
    useDriverNotificationsState,
    useAccountState,
    getTripSettings,
} from '../../../../Context';
import './index.scss';
import Loader from '../../../../Components/Loader';

const DriverNotifications = (props) => {
    const {
        notificationsDispatch,
        notifications: { notifications },
    } = useDriverNotificationsState();
    const { AccountsDispatch } = useAccountState();
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        (async () => {
            setLoading(true);
            await getTripSettings(AccountsDispatch);
            await getAllNotifications(notificationsDispatch);
            setLoading(false);
        })();
    }, [AccountsDispatch, notificationsDispatch]);

    return (
        <>
            <div className="w-100 mb-3 mt-1 content-section" style={{ minHeight: 'auto' }}>
                <Row className="mx-2">
                    <Col xs={12} className="px-0">
                        <div className="d-flex head-div mt-2">
                            <div className="col-8">
                                <div className="card-l">
                                    <span className="sg-card-lt">{'Message'}</span>
                                </div>
                            </div>
                            <div className="col-4 text-right">
                                <div className="card-r">
                                    <span className="sg-card-rt">{'Received'}</span>
                                </div>
                            </div>
                        </div>
                    </Col>
                    {notifications.map((data, i) => (
                        <Col
                            key={i}
                            xs={12}
                            className="px-0 msg-div"
                            onClick={() => props.history.push(`/driver/notifications/${data.id}`)}
                        >
                            <div className="d-flex mt-1">
                                <div className="col-8">
                                    <span className={`msg-sub ${data.readAt ? '' : 'bold'}`}>{data.subject}</span>
                                    <span className="msg-body">{data.content}</span>
                                </div>
                                <div className="col-4 text-right">
                                    <span className="">{data.createdAt}</span>
                                </div>
                            </div>
                        </Col>
                    ))}
                </Row>
            </div>
            {loading && <Loader />}
        </>
    );
};

export default DriverNotifications;
