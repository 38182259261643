import React, { useEffect, useState } from 'react';
import { Card, Button, Spinner } from 'react-bootstrap';
import ListingTable from '../../../../Components/ListingTable';
import { useAccountState, getTripSettings } from '../../../../Context';
import Loader from '../../../../Components/Loader';
import DetailsPageLayout from '../../../../Components/DetailsPageLayout/DetailsPageLayout';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { useTicketPassengerState } from '../../../../Context/context';
import { getPurchasedTicketPassengers } from '../../../../Context/actions/ticketPassengerAction';
import {
    checkInOutPassengerService,
    printPurchasedTicketPassengersService,
} from '../../../../services/ticketPassengerService';

const TourPassengers = (props) => {
    const dispatchId = props && props.match.params.id;

    const {
        AccountsDispatch,
        tripSettings: { tripSettings },
    } = useAccountState();
    const {
        ticketPassengerDispatch,
        ticketPassenger: { purchasedTicketPassengers },
    } = useTicketPassengerState();
    const [loading, setLoading] = useState(false);
    const [checkInLoading, setCheckInLoading] = useState(false);
    const [editId, setEditId] = useState('');
    const [width, setWidth] = useState(window.innerWidth);

    const updateDimensions = () => {
        setWidth(window.innerWidth);
    };
    useEffect(() => {
        window.addEventListener('resize', updateDimensions);
        return () => window.removeEventListener('resize', updateDimensions);
    }, []);

    useEffect(() => {
        (async () => {
            setLoading(true);
            await getTripSettings(AccountsDispatch);
            await getPurchasedTicketPassengers(ticketPassengerDispatch, dispatchId);
            setLoading(false);
        })();
    }, [AccountsDispatch, dispatchId, ticketPassengerDispatch]);

    const checkInOutPassenger = async (item) => {
        let updateData = {
            checkInTime: !item.checkInTime ? new Date() : null,
        };
        setEditId(item.id);
        setCheckInLoading(true);
        await checkInOutPassengerService(item.id, updateData);
        await getPurchasedTicketPassengers(ticketPassengerDispatch, dispatchId);
        setCheckInLoading(false);
        setEditId('');
    };
    const checkInOutRenderer = (item) => {
        const isLoading = editId === item.id && checkInLoading;
        const buttonVariant = item.checkInTime ? 'danger' : 'success';
        const buttonIcon = item.checkInTime ? 'fas fa-times-circle' : 'fas fa-check-circle';

        return (
            <>
                <Button
                    variant={buttonVariant}
                    onClick={() => checkInOutPassenger(item)}
                    className={`bg-${buttonVariant} text-white`}
                >
                    <i className={buttonIcon} />
                </Button>
                {isLoading && (
                    <Spinner
                        animation="border"
                        variant="success"
                        style={{
                            width: '1.5rem',
                            height: '1.5rem',
                            position: 'absolute',
                            marginLeft: '5px',
                            margin: '6px 6px auto',
                        }}
                    />
                )}
            </>
        );
    };

    let headingBackButtonObj = {
        title: ' BACK TO ASSIGNMENT',
        url: `/driver/assignments/${dispatchId}`,
    };

    const PrintPdf = async () => {
        try {
            setLoading(true);
            const { data: pdfData } = await printPurchasedTicketPassengersService(dispatchId);
            setLoading(false);
            let blob = new Blob([pdfData], { type: 'application/pdf' });
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'Ticket Passengers';
            link.click();
        } catch (e) {
            setLoading(false);
            console.log(e);
        }
    };

    return (
        <DetailsPageLayout
            title={width > 768 ? 'Passengers' : ''}
            icon={width > 768 ? 'fa fa-user' : ''}
            textColor={tripSettings?.textColor}
            headerActions={
                <Button style={{ float: 'right' }} variant={'primary'} as={Link} to={headingBackButtonObj.url}>
                    <i className="fas fa-chevron-left" /> {headingBackButtonObj.title}
                </Button>
            }
        >
            <Card style={{ maxWidth: '100%' }}>
                <Card.Header
                    className="d-flex justify-content-between flex-sm-row"
                    style={{ backgroundColor: `${tripSettings?.mainColor}` }}
                >
                    <h3 className="text-white m-0 w-45">{'Passengers'}</h3>
                    <Button
                        id="btnAddTicketUpgrade"
                        variant="info"
                        disabled={loading || !dispatchId}
                        onClick={PrintPdf}
                    >
                        Print Passenger List
                    </Button>
                </Card.Header>
                <Card.Body className="light-gray-bg" style={{ maxWidth: '100%' }}>
                    <ListingTable
                        data={purchasedTicketPassengers}
                        loading={loading}
                        mainColor={tripSettings?.mainColor}
                        pageInfo={{ first: 0, last: purchasedTicketPassengers.length }}
                        fieldNames={[
                            ['ticketPurchaseOrderID', 'Order #'],
                            ['fullName', 'Name'],
                            ['checkInTime', 'Check In', false, checkInOutRenderer],
                        ]}
                    />
                </Card.Body>
            </Card>
            {loading && <Loader />}
        </DetailsPageLayout>
    );
};

export default TourPassengers;
