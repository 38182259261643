import { listingTableSort } from '../../utils/helper';
import React, { useCallback, useState } from 'react';
import { Spinner, Table } from 'react-bootstrap';

// fieldNames = [[ key, label, isSortable, customRenderFunc, isEditable, editFieldRenderFunction, ref ]]
// defaultSort = { key:keyOfData, order:true|false } // true=ascending,false=descending
const ListingTable = ({
    fieldNames,
    loading,
    data,
    pageInfo,
    noSort = false,
    onClick = () => {},
    rowStyle = () => {},
    defaultSort = {},
    tableEditable = false,
    editingRow = '',
    setEditingRow = () => {},
    wrap = true,
    primaryField = 'id',
    upHeader = null,
    bordered = false,
    customRow = null,
    className = '',
    setSort = () => {},
    rowClass = () => {},
    overflow = 'auto',
    mainColor,
}) => {
    let defaultSortConfig = defaultSort.key
        ? [defaultSort.key, Boolean(defaultSort.order)] // taking sort config from props
        : [fieldNames[0][0], true]; // taking first key of data as default sort key, in ascending order

    const [sortConfig, setSortConfig] = useState([...defaultSortConfig]); // [ key,true|false ] true=ascending,false=descending

    const setSorting = (key) => {
        if (sortConfig[0]) {
            // sortConfig has value, toggling true-false
            setSortConfig([key, !sortConfig[1]]);
            setSort([key, !sortConfig[1]]);
        } else {
            // sortConfig is empty
            setSortConfig([key, true]);
            setSort([key, true]);
        }
    };

    const sortFn = useCallback((d1, d2) => (noSort ? () => {} : listingTableSort(d1, d2, sortConfig)), [sortConfig]);

    const handleEditFieldClick = (e, item, key) => {
        e.stopPropagation();
        setEditingRow({ id: item[primaryField], key });
    };

    const renderField = (key, i, item, customRenderFunc, isEditable, editFieldRenderFunction, handleEditClick, ref) => {
        let field = (
            <td onClick={(e) => isEditable && handleEditClick(e, item, key)} className="text-center" key={i}>
                {item[key]}
            </td>
        ); // rendering normal field data
        if (customRenderFunc)
            field = (
                <td onClick={(e) => isEditable && handleEditClick(e, item, key)} className="text-center" key={i}>
                    {customRenderFunc(item)}
                </td>
            ); // rendering custom field like "checkbox"
        if (isEditable && editingRow.id === item[primaryField] && editingRow.key === key && editFieldRenderFunction) {
            // rendering input if current row recordId is editing and field is editable
            field = (
                <td id="editField" ref={ref} className="text-center" key={i}>
                    {editFieldRenderFunction(item)}
                </td>
            );
        }
        return field;
    };

    return (
        <Table striped bordered={bordered} className={className} style={{ maxWidth: '100%' }}>
            <thead style={{ position: 'sticky', top: 0 }}>
                {upHeader}
                <tr
                    style={{
                        whiteSpace: wrap ? 'nowrap' : 'unset',
                        cursor: 'pointer',
                        backgroundColor: `${mainColor}`,
                    }}
                >
                    {
                        // render header items
                        fieldNames.map(([key, label, isSortable], i) => {
                            // field = [key, label, isSortable, customRenderFunc]
                            return (
                                <th key={i} className="text-center" onClick={() => isSortable && setSorting(key)}>
                                    {label}
                                    &nbsp;
                                    {sortConfig[0] === key ? (
                                        sortConfig[1] ? (
                                            <i className="fa fa-caret-down" aria-hidden="true" />
                                        ) : (
                                            <i className="fa fa-caret-up" aria-hidden="true" />
                                        )
                                    ) : (
                                        ''
                                    )}
                                </th>
                            );
                        })
                    }
                </tr>
            </thead>
            <tbody className={`overflow-${overflow}`}>
                {customRow}
                {!loading ? (
                    Array.isArray(data) ? (
                        data.length > 0 ? (
                            data
                                .sort(sortFn)
                                .slice(pageInfo.first, pageInfo.last)
                                .map((item, j) => (
                                    <tr
                                        key={j}
                                        onClick={() => editingRow === '' && onClick(item)}
                                        style={rowStyle(item)}
                                        className={`cursor-pointer ${wrap && 'text-nowrap'} ${rowClass(item)}`}
                                    >
                                        {fieldNames.map(
                                            (
                                                [key, , , customRenderFunc, isEditable, editFieldRenderFunction, ref],
                                                i
                                            ) =>
                                                renderField(
                                                    key,
                                                    i,
                                                    item,
                                                    customRenderFunc,
                                                    isEditable,
                                                    editFieldRenderFunction,
                                                    handleEditFieldClick,
                                                    ref
                                                )
                                        )}
                                    </tr>
                                ))
                        ) : (
                            <tr>
                                <td
                                    colSpan={fieldNames.length + Number(tableEditable)}
                                    className="text-center font-weight-bold"
                                >
                                    No Data Found
                                </td>
                            </tr>
                        )
                    ) : (
                        <tr>
                            <td
                                colSpan={fieldNames.length + Number(tableEditable)}
                                className="text-center font-weight-bold"
                            >
                                No Data Found
                            </td>
                        </tr>
                    )
                ) : (
                    <tr>
                        <td colSpan={fieldNames.length + Number(tableEditable)} className="text-center">
                            <Spinner animation="border" variant="primary" />
                        </td>
                    </tr>
                )}
            </tbody>
        </Table>
    );
};

export default ListingTable;
