import {
    getAllDriverNotifications,
    getDriverNotification,
    markNotificationAsRead,
} from '../../services/driverNotificationsService';

export async function getAllNotifications(dispatch) {
    try {
        dispatch({ type: 'REQUEST_LIST_NOTIFICATIONS' });
        let response = await getAllDriverNotifications();
        if (response.data) {
            dispatch({ type: 'NOTIFICATIONS_LIST_SUCCESS', payload: response.data });
            return response.data;
        }
        dispatch({
            type: 'NOTIFICATIONS_LIST_ERROR',
            error: response.data.errors[0],
        });
        return;
    } catch (error) {
        dispatch({ type: 'NOTIFICATIONS_LIST_ERROR', error: error });
    }
}

export async function getNotificationDetails(dispatch, notificationId) {
    try {
        dispatch({ type: 'REQUEST_NOTIFICATIONS_DETAILS' });
        let response = await getDriverNotification(notificationId);
        if (response.data) {
            dispatch({
                type: 'DRIVER_NOTIFICATIONS_SUCCESS',
                payload: response.data,
            });
            return response.data;
        }
        dispatch({
            type: 'DRIVER_NOTIFICATIONS_ERROR',
            error: response.data.errors[0],
        });
        return;
    } catch (error) {
        dispatch({ type: 'DRIVER_NOTIFICATIONS_ERROR', error: error });
    }
}

export async function markAsRead(dispatch, notificationId) {
    try {
        dispatch({ type: 'REQUEST_NOTIFICATIONS_DETAILS' });
        let response = await markNotificationAsRead(notificationId);

        if (response.data) {
            return response.data;
        }

        dispatch({
            type: 'DRIVER_NOTIFICATIONS_ERROR',
            error: response.data.errors[0],
        });
        return;
    } catch (error) {
        dispatch({ type: 'DRIVER_NOTIFICATIONS_ERROR', error: error });
    }
}
