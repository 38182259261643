import React, { useState, useEffect } from 'react';
import { Button, Form, Col, Row, Spinner, Image, Modal } from 'react-bootstrap';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useAuthState } from '../../../Context';
import './ForgotPassword.scss';
import { getDriver, sendForgetPasswordEmail, updateDriver, getLoginImage } from '../../../Context/actions/authActions';

const ForgotPassword = (props) => {
    const schema = yup.object().shape({
        email: yup.string().email('Please enter a valid email').required('Email is required'),
    });

    const { register, handleSubmit } = useForm(schema);
    const [loading, setLoadingState] = useState(false);
    const {
        authDispatch,
        authUser: { errorMessage, loginImage },
    } = useAuthState();
    const [successMessage, setSuccessMessage] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                setLoadingState(true);
                await getLoginImage(authDispatch);
                setLoadingState(false);
            } catch (e) {
                console.log('e', e);
                setLoadingState(false);
            }
        })();
    }, [authDispatch]);

    const onSubmit = () => {
        handleSubmit(async (data) => {
            setLoadingState(true);
            try {
                setLoadingState(true);
                let response = await getDriver(authDispatch, data);
                if (response) {
                    let password = Math.random().toString(36).substr(2, 10);
                    const updatedDriver = await updateDriver(authDispatch, { password }, response.id);
                    if (updatedDriver && updatedDriver.length) {
                        await sendForgetPasswordEmail(authDispatch, {
                            email: response.email,
                            password,
                            companyName: response?.Company?.companyName,
                        });
                    }
                }
                setSuccessMessage(true);
            } catch (error) {
                console.log(error);
            }
            setLoadingState(false);
        })();
    };

    const handleLogin = () => {
        props.history.push('/login');
    };

    return (
        <>
            <div className="forgot-page-wrapper">
                <div className="d-flex forgot-box ">
                    <div className="images-wrap ">
                        <Image src={loginImage.driverPortalLoginImageURL} style={{ width: '100%', height: 'auto' }} />
                    </div>
                    <div className="login-box-content">
                        <div className="box-content w-100">
                            {successMessage ? (
                                <div className="box-input d-flex justify-content-center align-items-center  ">
                                    <h3>LOST PASSWORD?</h3>
                                    <h6>
                                        Thank you. If the email you entered has an account associated with it, an email
                                        has been sent with password reset instructions.
                                    </h6>

                                    <div className="w-100 ">
                                        <div className="reset-link-button ">
                                            <div className="login">
                                                <a href="#" onClick={handleLogin}>
                                                    BACK TO LOGIN
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="box-input d-flex justify-content-center align-items-center  ">
                                    <h3>LOST PASSWORD?</h3>
                                    <h6>
                                        No worries, we can help! Enter your email below and we will send you a link to
                                        reset your password.
                                    </h6>

                                    <Row className="w-100 form-box  ">
                                        <Col>
                                            <div>
                                                <div className="form-input">
                                                    <Form.Group>
                                                        <Col>
                                                            <Form.Control
                                                                type="email"
                                                                placeholder="Enter your email address here"
                                                                name="email"
                                                                required
                                                                autoComplete="off"
                                                                isInvalid={errorMessage}
                                                                {...register('email', { required: true })}
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <div className="w-100 ">
                                        <div className="sign-up-button ">
                                            <Button id="login" onClick={onSubmit} variant="info">
                                                SEND RESET LINK
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {loading && (
                <>
                    <Modal transition="false" show={true} className="loading-modal text-center">
                        <Spinner animation="border" variant="primary" />
                    </Modal>
                </>
            )}
        </>
    );
};

export default ForgotPassword;
