export const getCompanyId = () => {
    let user = getCustomerDetails();
    return user['companyID'];
};

export const getEnterpriseId = () => {
    let url = window.location.href;
    const urlObject = new URL(url);
    const hostName = urlObject.hostname;
    let enterprise = '';
    let enterprises = [
        { id: 21, hostname: 'localhost' },
        { id: 21, hostname: 'tbn-driver-portal-dev.web.app' },
        { id: 21, hostname: 'tbn-driver-portal-qa.web.app' },
        { id: 2, hostname: 'dattcodriverportal.thebusnetwork.com' },
        { id: 1, hostname: 'opv2driverportal.thebusnetwork.com' },
        { id: 4, hostname: 'driverportal.thebusnetwork.com' },
        { id: 4, hostname: 'driverportal.tbndrives.com' },
        { id: 10, hostname: 'lvtdrivers.tbndrives.com' },
        { id: 10, hostname: 'lvtdrivers.thebusnetwork.com' },
        { id: 21, hostname: 'tbn-driver-portal-staging.web.app' },
        { id: 16, hostname: 'starrdriverportal.thebusnetwork.com' },
        { id: 17, hostname: 'driver.goanderson.com' },
        { id: 17, hostname: 'andersondriver.tbndrives.com' },
        { id: 35, hostname: 'voigtdrivers.tbndrives.com' },
        { id: 36, hostname: 'ebmeyerdrivers.tbndrives.com' },
        { id: 43, hostname: 'hamptonjitneydrivers.tbndrives.com' },
        { id: 39, hostname: 'christianbrosdrivers.tbndrives.com' },
        { id: 41, hostname: 'elitecoachdrivers.tbndrives.com' },
        { id: 40, hostname: 'affordablelimodrivers.tbndrives.com' },
        { id: 34, hostname: 'wernercoachdrivers.tbndrives.com' },
        { id: 38, hostname: 'king-graydrivers.tbndrives.com' },
        { id: 30, hostname: 'besttoursdrivers.tbndrives.com' },
        { id: 26, hostname: 'panoramadrivers.tbndrives.com' },
        { id: 44, hostname: 'jamesriverdrivers.tbndrives.com' },
        { id: 45, hostname: 'pacificcrestdrivers.tbndrives.com' },
        { id: 46, hostname: 'youngdrivers.tbndrives.com' },
        { id: 2, hostname: 'dattcodrivers.tbndrives.com' },
        { id: 2, hostname: 'drivers.dattco.com' },
        { id: 53, hostname: 'americancharterlinesdrivers.tbndrives.com' },
        { id: 54, hostname: 'abadrivers.tbndrives.com' },
        { id: 55, hostname: 'clinetoursdrivers.tbndrives.com' },
        { id: 56, hostname: 'empirecoachlinedrivers.tbndrives.com' },
        { id: 24, hostname: 'gonectdrivers.tbndrives.com' },
        { id: 52, hostname: 'vandaliadrivers.tbndrives.com' },
        { id: 47, hostname: 'venturebustoursdrivers.tbndrives.com' },
        { id: 11, hostname: 'leprechaunlinesdrivers.tbndrives.com' },
        { id: 27, hostname: 'bloomdrivers.tbndrives.com' },
        { id: 57, hostname: 'northfielddrivers.tbndrives.com' },
        { id: 8, hostname: 'krapfdrivers.tbndrives.com' },
        { id: 25, hostname: 'westpointdrivers.tbndrives.com' },
        { id: 50, hostname: 'trobecsdrivers.tbndrives.com' },
        { id: 49, hostname: 'littlerockdrivers.tbndrives.com' },
        { id: 48, hostname: 'brtcharterdrivers.tbndrives.com' },
        { id: 29, hostname: 'astrodrivers.tbndrives.com' },
        { id: 5, hostname: 'starlinedrivers.tbndrives.com' },
        { id: 6, hostname: 'annettdrivers.tbndrives.com' },
        { id: 3, hostname: 'dandipertdrivers.tbndrives.com' },
        { id: 42, hostname: 'davidtoursdrivers.tbndrives.com' },
        { id: 7, hostname: 'graylinetndrivers.tbndrives.com' },
        { id: 22, hostname: 'hlcharterdrivers.tbndrives.com' },
        { id: 19, hostname: 'kissalaskadrivers.tbndrives.com' },
        { id: 9, hostname: 'lamersdrivers.tbndrives.com' },
        { id: 10, hostname: 'lamoilledrivers.tbndrives.com' },
        { id: 33, hostname: 'mayodrivers.tbndrives.com' },
        { id: 13, hostname: 'mtrwesterndrivers.tbndrives.com' },
        { id: 14, hostname: 'nwnavigatordrivers.tbndrives.com' },
        { id: 32, hostname: 'polyaddrivers.tbndrives.com' },
        { id: 15, hostname: 'royaldrivers.tbndrives.com' },
        { id: 18, hostname: 'swarthoutdrivers.tbndrives.com' },
        { id: 37, hostname: 'windstardrivers.tbndrives.com' },
        { id: 59, hostname: 'westerndrivers.tbndrives.com' },
        { id: 58, hostname: 'qualityassurancedriverprod.tbndrives.com' },
        { id: 58, hostname: 'qualityassurancedriverqa.tbndrives.com' },
        { id: 63, hostname: 'americanstardrivers.tbndrives.com' },
        { id: 65, hostname: 'waymakerdrivers.tbndrives.com' },
        { id: 64, hostname: 'decampdrivers.tbndrives.com' },
        { id: 66, hostname: 'customcoachdrivers.tbndrives.com' },
        { id: 67, hostname: 'niagaradrivers.tbndrives.com' },
        { id: 68, hostname: 'redstardrivers.tbndrives.com' },
        { id: 69, hostname: 'signaturedrivers.tbndrives.com' },
        { id: 70, hostname: 'firstclassdrivers.tbndrives.com' },
        { id: 71, hostname: 'campbelldrivers.tbndrives.com' },
        { id: 72, hostname: 'dfdrivers.tbndrives.com' },
        { id: 73, hostname: 'fullingtondrivers.tbndrives.com' },
        { id: 74, hostname: 'agoffdrivers.tbndrives.com' },
        { id: 75, hostname: 'dctrailsdriver.tbndrives.com' },
        { id: 76, hostname: 'freeenterprisedrivers.tbndrives.com' },
        { id: 78, hostname: 'transportationservicesdrivers.tbndrives.com' },
        { id: 79, hostname: 'shafersdrivers.tbndrives.com' },
        { id: 60, hostname: 'allarounddrivers.tbndrives.com' },
        { id: 77, hostname: 'groundtraveldrivers.tbndrives.com' },
        { id: 12, hostname: 'lorenzdrivers.tbndrives.com' },
        { id: 80, hostname: 'bluelakesdrivers.tbndrives.com' },
        { id: 81, hostname: 'cowtowndrivers.tbndrives.com' },
        { id: 61, hostname: 'haledrivers.tbndrives.com' },
        { id: 82, hostname: 'nationaltrailsdrivers.tbndrives.com' },
        { id: 83, hostname: 'baronsdrivers.tbndrives.com' },
        { id: 84, hostname: 'uniquelimodrivers.tbndrives.com' },
        { id: 85, hostname: 'croswelldrivers.tbndrives.com' },
        { id: 86, hostname: 'crosscountrydrivers.tbndrives.com' },
        { id: 87, hostname: 'journeydrivers.tbndrives.com' },
        { id: 88, hostname: 'agapedrivers.tbndrives.com' },
        { id: 89, hostname: 'newmexicotexasdrivers.tbndrives.com' },
        { id: 90, hostname: 'bluestardrivers.tbndrives.com' },
    ];
    enterprises.forEach((c) => {
        if (hostName === c.hostname) {
            enterprise = c.id;
        }
    });
    return enterprise;
};

export const getUserId = () => {
    return localStorage.getItem('user') && JSON.parse(localStorage.getItem('user')).id;
};

export const getCustomerDetails = () => {
    return JSON.parse(localStorage.getItem('user'));
};

export const timezoneDescription = () => {
    return localStorage.getItem('user') && JSON.parse(localStorage.getItem('user')).timeZoneDescription;
};
