export const initialStateYards = {
    yards: [],
    loading: false,
    errorMessage: null,
};

export const YardsReducer = (state = initialStateYards, action) => {
    switch (action.type) {
        case 'GET_YARDS_SUCCESS':
            return {
                ...state,
                yards: action.payload,
                loading: false,
            };

        case 'GET_YARDS_ERROR':
            return {
                ...state,
                loading: false,
                errorMessage: action.error,
            };

        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};
