import React from 'react';
import { Col, Row, Card } from 'react-bootstrap';
import './style.module.scss';

const ListingLayout = ({
    title,
    titleIconClass,
    headerActions = null,
    cardHeader = null,
    listingTable = null,
    children = null,
    middleContent = null,
    textColor,
}) => {
    return (
        <div className="w-100 mb-3" style={{ minHeight: 'auto' }}>
            <Row className="mx-3">
                <Col xs={12}>
                    <div className="d-flex justify-content-between w-100 mt-3 mb-3 flex-column flex-md-row">
                        <h3 style={{ color: `${textColor}` }}>
                            <i className={titleIconClass} /> {title}
                        </h3>
                        <div>{headerActions}</div>
                    </div>
                </Col>
            </Row>
            {middleContent}
            <Row className="mx-3">
                <Col xs={12} className="px-0">
                    <Card className="cardBox">
                        <Card.Header className="d-flex justify-content-between border-0 flex-wrap">
                            {cardHeader}
                        </Card.Header>
                        <div className="custom-body p-1">
                            {listingTable}
                            {children}
                        </div>
                        {/*<Card.Footer className="border-0 sticky-bottom bg-white">*/}
                        {/*    {listingTableFooter}*/}
                        {/*</Card.Footer>*/}
                    </Card>
                </Col>
            </Row>
            {/*<div className="px-3">*/}
            {/*    {children}*/}
            {/*</div>*/}
        </div>
    );
};

export default ListingLayout;
