import React, { useState } from 'react';
import { Modal, Button, Row, Col, FormGroup, Form, FormControl } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

export default function UpdatePasswordModal(props) {
    const { updatePassword, handlePasswordToggleModal, showCurrent, passwordCheck, user } = props;
    const schema = showCurrent
        ? yup.object().shape({
              password: yup.string().required('Password is required'),
              confirmPassword: yup.string().when('password', {
                  is: (val) => !!(val && val.length > 0),
                  then: yup.string().oneOf([yup.ref('password')], 'confirm password should be same as password'),
              }),
          })
        : yup.object().shape({
              password: yup.string().required('Password is required'),
              confirmPassword: yup.string().when('password', {
                  is: (val) => !!(val && val.length > 0),
                  then: yup.string().oneOf([yup.ref('password')], 'confirm password should be same as password'),
              }),
          });

    const { register, handleSubmit, errors, control } = useForm({
        resolver: yupResolver(schema),
    });
    const [currentPasswordError, setCurrentPasswordError] = useState(false);

    const onSubmit = async (data) => {
        !currentPasswordError && updatePassword(data);
    };

    const onCheckPassword = async (password) => {
        try {
            const res = await passwordCheck(password, user.id);
            console.log(res);
            if (res.data === false) setCurrentPasswordError(true);
            else {
                setCurrentPasswordError(false);
            }
        } catch (err) {
            console.log('error', err);
        }
    };
    return (
        <div>
            <Modal size="md" show={true} onHide={() => () => handlePasswordToggleModal(false)}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Header>
                        <Modal.Title>Update Password</Modal.Title>
                        <i className="fas fa-times closeButton" onClick={() => handlePasswordToggleModal(false)} />
                    </Modal.Header>
                    <Modal.Body>
                        {showCurrent ? (
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Form.Label>Current Password</Form.Label>
                                        <Controller
                                            render={() => (
                                                <Form.Control
                                                    type="password"
                                                    placeholder="Current Password"
                                                    id="currentPassword"
                                                    name="currentPassword"
                                                    isInvalid={
                                                        errors && (errors.currentPassword || currentPasswordError)
                                                    }
                                                    onBlur={(e) => {
                                                        onCheckPassword(e.target.value);
                                                    }}
                                                />
                                            )}
                                            name="currentPassword"
                                            control={control}
                                        />
                                        <FormControl.Feedback type="invalid">
                                            {currentPasswordError
                                                ? 'Incorrect Password'
                                                : errors && errors.currentPassword?.message}
                                        </FormControl.Feedback>
                                    </FormGroup>
                                </Col>
                            </Row>
                        ) : (
                            ''
                        )}
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder="Password"
                                        name="password"
                                        isInvalid={errors && errors.password}
                                        {...register('password')}
                                    />

                                    <FormControl.Feedback type="invalid">
                                        {errors && errors.password?.message}
                                    </FormControl.Feedback>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Form.Label>Confirm Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder="Confirm Password"
                                        name="confirmPassword"
                                        isInvalid={errors && errors.confirmPassword}
                                        {...register('confirmPassword')}
                                    />
                                    <FormControl.Feedback type="invalid">
                                        {errors && errors.confirmPassword?.message}
                                    </FormControl.Feedback>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="primary"
                            className="btn w-25 pt-2 pb-2"
                            onClick={() => handlePasswordToggleModal(false)}
                        >
                            Cancel
                        </Button>
                        <Button type="submit" variant="secondary" className="btn pt-2 pb-2">
                            Update Password
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </div>
    );
}
