import React, { useEffect, useState } from 'react';
import { getProfileInfo, useProfilePageState, useAccountState, getTripSettings } from '../../../../Context';
import DetailsPageLayout from '../../../../Components/DetailsPageLayout/DetailsPageLayout';
import { Col, Form, FormGroup, Row, Card } from 'react-bootstrap';
import Loader from '../../../../Components/Loader';
import { useForm } from 'react-hook-form';
import CustomAccordion from '../../../../Components/CustomAccordion';
import './index.scss';

const ProfilePage = () => {
    const { profilePageDispatch } = useProfilePageState();
    const [loading, setLoading] = useState(false);
    const { reset, register } = useForm();
    const [profileTitle, setProfileTitle] = useState();
    const [collapseItem, handleCollapse] = useState({ 1: false });
    const {
        AccountsDispatch,
        tripSettings: { tripSettings },
    } = useAccountState();

    useEffect(() => {
        (async () => {
            setLoading(true);
            await getTripSettings(AccountsDispatch);
            let driverDetail = await getProfileInfo(profilePageDispatch);
            reset({ ...driverDetail });
            driverDetail && setProfileTitle(`${driverDetail.firstName}  ${driverDetail.lastName}`);
            setLoading(false);
        })();
    }, [AccountsDispatch, profilePageDispatch, reset]);

    const handleItemCollapse = (e) => {
        const { id } = e.target;
        handleCollapse({ ...collapseItem, [id]: !collapseItem[id] });
    };

    return (
        <DetailsPageLayout title="Driver" icon="fa fa-id-card" textColor={tripSettings?.textColor}>
            <div className="note-text">
                For any changes or updates to this information please contact a company representative.
            </div>
            <Card>
                <Card.Header className="d-flex" style={{ backgroundColor: `${tripSettings?.mainColor}` }}>
                    <h3 className="text-white m-0 w-50">{profileTitle}</h3>
                </Card.Header>
                <Card.Body className="light-gray-bg">
                    {/* CONTACT INFORMATION */}
                    <CustomAccordion
                        key={1}
                        id={1}
                        title="CONTACT INFORMATION"
                        handleItemCollapse={handleItemCollapse}
                        sectionColor={tripSettings?.sectionColor}
                        collapseItem={collapseItem}
                    >
                        <Row>
                            <Col xs={12} md={6} lg={4}>
                                <FormGroup>
                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="firstName"
                                        name="firstName"
                                        placeholder="First Name"
                                        {...register('firstName')}
                                        readOnly
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={12} md={6} lg={4}>
                                <FormGroup>
                                    <Form.Label>Salutation</Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="salutation"
                                        name="salutation"
                                        placeholder="Salutation"
                                        {...register('salutation')}
                                        readOnly
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={12} md={6} lg={4}>
                                <FormGroup>
                                    <Form.Label>Preferred Vehicle</Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="preferredVehicle"
                                        name="preferredVehicle"
                                        placeholder="Preferred Vehicle"
                                        {...register('preferredVehicle')}
                                        readOnly
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={6} lg={4}>
                                <FormGroup>
                                    <Form.Label>Mobile Phone #</Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="mobilePhone"
                                        name="mobilePhone"
                                        placeholder="Mobile Phone #"
                                        {...register('mobilePhone')}
                                        readOnly
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={12} md={6} lg={4}>
                                <FormGroup>
                                    <Form.Label>Middle Initial</Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="middleInitial"
                                        name="middleInitial"
                                        placeholder="Middle Initial"
                                        {...register('middleInitial')}
                                        readOnly
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={12} md={6} lg={4}>
                                <FormGroup>
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="lastName"
                                        name="lastName"
                                        placeholder="Last Name"
                                        {...register('lastName')}
                                        readOnly
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={6} lg={4}>
                                <FormGroup>
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="email"
                                        name="email"
                                        placeholder="Email"
                                        {...register('email')}
                                        readOnly
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={12} md={6} lg={4}>
                                <FormGroup>
                                    <Form.Label>Home Phone #</Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="homePhone"
                                        name="homePhone"
                                        placeholder="Home Phone #"
                                        {...register('homePhone')}
                                        readOnly
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={12} md={6} lg={4}>
                                <FormGroup>
                                    <Form.Label>Alternate Phone #</Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="altPhone"
                                        name="altPhone"
                                        placeholder="Alternate Phone #"
                                        {...register('altPhone')}
                                        readOnly
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={8} lg={4} className="m-auto">
                                <FormGroup>
                                    <Form.Label>Address</Form.Label>
                                    <Row className="no-gutters mx-0">
                                        <Col xs={12} className={'mt-2'}>
                                            <Form.Control
                                                type="text"
                                                name="addressStreet1"
                                                id="addressStreet1"
                                                {...register('addressStreet1')}
                                                placeholder="Address 1"
                                                readOnly
                                            />
                                        </Col>
                                        <Col xs={12} className="mt-2">
                                            <Form.Control
                                                type="text"
                                                name="addressStreet2"
                                                id="addressStreet2"
                                                {...register('addressStreet2')}
                                                placeholder="Address 2"
                                                readOnly
                                            />
                                        </Col>
                                        <Col xs={12} className="mt-2">
                                            <Form.Control
                                                type="text"
                                                name="addressCity"
                                                id="addressCity"
                                                {...register('addressCity')}
                                                placeholder="City"
                                                readOnly
                                            />
                                        </Col>
                                        <Col xs={12} md={7} className="mt-2">
                                            <Form.Control
                                                type="text"
                                                name="addressState"
                                                id="addressState"
                                                {...register('addressState')}
                                                placeholder="State"
                                                readOnly
                                            />
                                        </Col>
                                        <Col xs={12} md={5} className="mt-2 pl-0 pl-md-3">
                                            <Form.Control
                                                type="text"
                                                id="addressZip"
                                                name="addressZip"
                                                {...register('addressZip')}
                                                placeholder="Zip"
                                                readOnly
                                            />
                                        </Col>
                                    </Row>
                                </FormGroup>
                            </Col>
                        </Row>
                    </CustomAccordion>

                    <CustomAccordion
                        key={2}
                        id={2}
                        title="EMPLOYMENT INFORMATION"
                        handleItemCollapse={handleItemCollapse}
                        sectionColor={tripSettings?.sectionColor}
                        collapseItem={collapseItem}
                    >
                        <Row>
                            {/*Employment Status*/}
                            <Col xs={12} md={6} lg={4}>
                                <FormGroup>
                                    <Form.Label>Employment Status</Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="employmentStatus"
                                        name="employmentStatus"
                                        placeholder="Employment Status"
                                        {...register('employmentStatus')}
                                        readOnly
                                    />
                                </FormGroup>
                            </Col>
                            {/*Date of Employment - Start*/}
                            <Col xs={12} md={6} lg={4}>
                                <FormGroup>
                                    <Form.Label>Date of Employment - Start</Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="dateEmploymentStart"
                                        name="dateEmploymentStart"
                                        placeholder="Date of Employment - Start"
                                        {...register('dateEmploymentStart')}
                                        readOnly
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={6} lg={6}>
                                <FormGroup>
                                    <Form.Label>Primary Location</Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="primaryLocation"
                                        name="primaryLocation"
                                        placeholder="Primary Location"
                                        {...register('primaryLocation')}
                                        readOnly
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            {/*License Number*/}
                            <Col xs={12} md={6} lg={4}>
                                <FormGroup>
                                    <Form.Label>License Number</Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="licenseNumber"
                                        name="licenseNumber"
                                        placeholder="License Number"
                                        {...register('licenseNumber')}
                                        readOnly
                                    />
                                </FormGroup>
                            </Col>
                            {/*License Issued State*/}
                            <Col xs={12} md={6} lg={4}>
                                <FormGroup>
                                    <Form.Label>License Issued State</Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="licenseIssuedState"
                                        name="licenseIssuedState"
                                        placeholder="License Issued State"
                                        {...register('licenseIssuedState')}
                                        readOnly
                                    />
                                </FormGroup>
                            </Col>
                            {/*License Issued Date*/}
                            <Col xs={12} md={6} lg={2}>
                                <FormGroup>
                                    <Form.Label>License Issued Date</Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="dateLicenseIssued"
                                        name="dateLicenseIssued"
                                        placeholder="License Issued Date"
                                        {...register('dateLicenseIssued')}
                                        readOnly
                                    />
                                </FormGroup>
                            </Col>
                            {/*License Expiration Date*/}
                            <Col xs={12} md={6} lg={2}>
                                <FormGroup>
                                    <Form.Label>License Expiration Date</Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="dateLicenseExpired"
                                        name="dateLicenseExpired"
                                        placeholder="License Expiration Date"
                                        {...register('dateLicenseExpired')}
                                        readOnly
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            {/*Date of Birth*/}
                            <Col xs={12} md={6} lg={4}>
                                <FormGroup>
                                    <Form.Label>Date of Birth</Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="dateOfBirth"
                                        name="dateOfBirth"
                                        placeholder="Date of Birth"
                                        {...register('dateOfBirth')}
                                        readOnly
                                    />
                                </FormGroup>
                            </Col>
                            {/*Medical Card Expiration Date*/}
                            <Col xs={12} md={6} lg={4}>
                                <FormGroup>
                                    <Form.Label>Medical Card Expiration Date</Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="dateMedicalCardExpiration"
                                        name="dateMedicalCardExpiration"
                                        placeholder="Medical Card Expiration Date"
                                        {...register('dateMedicalCardExpiration')}
                                        readOnly
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={12} md={6} lg={4}>
                                <FormGroup>
                                    <Form.Label>Passport Expiration Date</Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="datePassportExpiration"
                                        name="datePassportExpiration"
                                        placeholder="Passport Expiration Date"
                                        {...register('datePassportExpiration')}
                                        readOnly
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </CustomAccordion>
                </Card.Body>
            </Card>
            {loading && <Loader />}
        </DetailsPageLayout>
    );
};

export default ProfilePage;
