import React, { useEffect, useState } from 'react';
import { getProfileInfo, useProfilePageState, useAccountState, getTripSettings } from '../../../../Context';
import { Row } from 'react-bootstrap';
import Loader from '../../../../Components/Loader';
import { useForm } from 'react-hook-form';
import './index.scss';

const ProfilePage = () => {
    const { profilePageDispatch } = useProfilePageState();
    const [loading, setLoading] = useState(false);
    const { reset } = useForm();
    const [profileTitle, setProfileTitle] = useState();
    const [profileDetail, setProfileDetail] = useState({});
    const { AccountsDispatch } = useAccountState();

    useEffect(() => {
        (async () => {
            setLoading(true);
            await getTripSettings(AccountsDispatch);
            let driverDetail = await getProfileInfo(profilePageDispatch);
            reset({ ...driverDetail });
            setProfileDetail(driverDetail);
            driverDetail && setProfileTitle(`${driverDetail.firstName}  ${driverDetail.lastName}`);
            setLoading(false);
        })();
    }, [AccountsDispatch, profilePageDispatch, reset]);

    const combineAddress = (add) => {
        const { streetAddress1 = '', streetAddress2 = '', addressCity = '', zipAddress = '', addressState = '' } = add;
        return [streetAddress1, streetAddress2, addressCity, addressState, zipAddress].filter(Boolean).join(', ');
    };

    return (
        <>
            <div className="w-100 mb-3 mt-1 content-section" style={{ minHeight: 'auto' }}>
                <Row className="mx-2">
                    <div className="col-12 head-div mt-2">
                        <div>
                            <span className="sg-card-lt">{'Contact Information'}</span>
                        </div>
                    </div>
                    <div className="col-12 ">
                        <span className="dtail-bold">{profileTitle}</span>
                        {profileDetail.mobilePhone && (
                            <span className="dtail">Mobile: {profileDetail.mobilePhone}</span>
                        )}
                        {profileDetail.homePhone && <span className="dtail">Home: {profileDetail.homePhone}</span>}
                        {profileDetail.email && <span className="dtail">Email: {profileDetail.email}</span>}
                        {(profileDetail.addressStreet1 || profileDetail.addressStreet2) && (
                            <span className="dtail">
                                Adddress:{' '}
                                {combineAddress({
                                    streetAddress1: profileDetail.addressStreet1,
                                    streetAddress2: profileDetail.addressStreet2,
                                    addressCity: profileDetail.addressCity,
                                    addressState: profileDetail.addressState,
                                    zipAddress: profileDetail.addressZip,
                                })}
                            </span>
                        )}
                        {profileDetail.dateOfBirth && <span className="dtail">DOB: {profileDetail.dateOfBirth}</span>}
                    </div>
                    <div className="col-12 head-div mt-2">
                        <div>
                            <span className="sg-card-lt">{'Employment Information'}</span>
                        </div>
                    </div>
                    <div className="col-12 ">
                        <span className="dtail">Employment status: {profileDetail.employmentStatus}</span>
                        <span className="dtail">Start of Employment: {profileDetail.dateEmploymentStart}</span>
                        <span className="dtail">Primary Location: {profileDetail.primaryLocation}</span>
                    </div>
                    <div className="col-12 head-div mt-2">
                        <div>
                            <span className="sg-card-lt">{'License Information'}</span>
                        </div>
                    </div>
                    <div className="col-12 ">
                        <span className="dtail">License Number: {profileDetail.licenseNumber}</span>
                        <span className="dtail">License State: {profileDetail.licenseIssuedState}</span>
                        <span className="dtail">License Issue Date: {profileDetail.dateLicenseIssued}</span>
                        <span className="dtail">License Expire Date: {profileDetail.dateLicenseExpired}</span>
                        <span className="dtail mt-2">
                            Medical Card Expiration Date: {profileDetail.dateMedicalCardExpiration}
                        </span>
                        <span className="dtail">Passport Expiration Date: {profileDetail.datePassportExpiration}</span>
                    </div>
                </Row>
            </div>
            {loading && <Loader />}
        </>
    );
};

export default ProfilePage;
