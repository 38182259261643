import { getThemeConfigService } from '../../services/themeConfigService';

export async function setMockupTheme(dispatch) {
    let mockupTheme = {
        backgroundColor: 'gray',
        fontColor: 'white',
        logo: 'https://www.thebusnetwork.com/wp-content/uploads/2020/08/DATTCO-Logo.jpg',
        footerLogo:
            'https://storage.googleapis.com/the-bus-network-bucket/account-settings/large-powered-by-white_FApW7.png',
    };
    try {
        dispatch({ type: 'REQUEST_FETCH_MOCKUP_THEME' });
        let response = await getThemeConfigService();
        if (response.data) {
            let config = response.data;
            mockupTheme = {
                backgroundColor: config.backgroundColor,
                fontColor: config.fontColor,
                primaryTextColor: config.primaryTextColor,
                logo: config.logo,
                footerLogo: config.footerLogo,
            };
        }
        dispatch({ type: 'SET_MOCKUP_THEME', payload: mockupTheme });
    } catch (error) {
        dispatch({ type: 'SET_MOCKUP_THEME', payload: mockupTheme });
    }
}
