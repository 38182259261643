export const initialStateAuth = {
    user: '',
    token: '',
    users: [],
    loading: false,
    errorMessage: null,
    logo: '',
    loginImage: '',
};

export const AuthReducer = (initialStateAuth, action) => {
    switch (action.type) {
        case 'REQUEST_LOGIN':
            return {
                ...initialStateAuth,
                loading: true,
            };
        case 'LOGIN_SUCCESS':
            return {
                ...initialStateAuth,
                userDetails: action.payload,
                user: action.payload,
                token: action.payload.auth_token,
                errorMessage: null,
                loading: false,
            };
        case 'LOGOUT':
            return {
                ...initialStateAuth,
                user: '',
                token: '',
            };

        case 'LOGIN_ERROR':
            return {
                ...initialStateAuth,
                loading: false,
                errorMessage: action.error,
            };
        case 'GET_DRIVER_SUCCESS':
            return {
                ...initialStateAuth,
                users: [...initialStateAuth.users, action.payload],
                loading: false,
                errorMessage: null,
            };
        case 'GET_DRIVER_ERROR':
            return {
                ...initialStateAuth,
                users: [],
                loading: false,
                errorMessage: action.err,
            };
        case 'UPDATE_DRIVER_SUCCESS':
            return {
                ...initialStateAuth,
                users: action.payload,
                loading: false,
                errorMessage: null,
            };
        case 'UPDATE_DRIVER_ERROR':
            return {
                ...initialStateAuth,
                users: [],
                loading: false,
                errorMessage: action.error,
            };
        case 'GET_LOGO_SUCCESS':
            return {
                ...initialStateAuth,
                logo: action.payload,
            };
        case 'REQUEST_LOGO':
            return {
                ...initialStateAuth,
                loading: true,
            };
        case 'GET_LOGO_ERROR':
            return {
                ...initialStateAuth,
                loading: false,
                errorMessage: action.error,
            };
        case 'GET_LOGIN_IMAGE_SUCCESS':
            return {
                ...initialStateAuth,
                loginImage: action.payload,
                loading: false,
            };
        case 'GET_LOGIN_IMAGE_ERROR':
            return {
                ...initialStateAuth,
                loading: false,
                errorMessage: action.error,
            };

        case 'SEND_EMAIL_SUCCESS':
            return {
                ...initialStateAuth,
                loading: false,
            };

        case 'SEND_EMAIL_ERROR':
            return {
                ...initialStateAuth,
                loading: false,
                errorMessage: action.error,
            };

        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};
