import { Redirect, Route, Switch } from 'react-router-dom';
import { useReducer, useEffect } from 'react';

// Routes
import MockupRoutes from './MockupRoutes';
import PublicRoute from './publicRoute';

import Login from '../Containers/views/Login';
import { AuthReducer, initialStateAuth } from '../Context/reducer/authReducer';
import { AuthStateContext } from '../Context';
import ForgotPassword from '../Containers/views/ForgotPassword/index';

const Routes = () => {
    const [authUser, authDispatch] = useReducer(AuthReducer, initialStateAuth);
    const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : '';

    useEffect(() => {
        if (localStorage.getItem('user')) {
            if (!sessionStorage.getItem('lastTimeStamp')) {
                localStorage.clear();
                window.location.href = '/login';
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    return (
        <Switch>
            <AuthStateContext.Provider value={{ authUser, authDispatch }}>
                <Route path="/driver/:path?/:id?">
                    <MockupRoutes />
                </Route>
                <Route path="/:id?" exact>
                    <PublicRoute path="/login" exact component={Login} />
                    <PublicRoute path="/forgot-password" exact component={ForgotPassword} />
                    <PublicRoute path="/" exact component={() => <Redirect to="/login" />} />
                </Route>
            </AuthStateContext.Provider>
        </Switch>
    );
};

export default Routes;
