import { getProfile } from '../../services/profilePageService';

export async function getProfileInfo(dispatch) {
    try {
        dispatch({ type: 'REQUEST_GET_PROFILE' });
        let response = await getProfile();
        if (response.data) {
            dispatch({ type: 'GET_PROFILE_SUCCESS', payload: response.data });
            return response.data;
        }
        dispatch({ type: 'GET_PROFILE_ERROR', error: response.data.errors[0] });
        return;
    } catch (error) {
        dispatch({ type: 'GET_PROFILE_ERROR', error: error });
    }
}
