export const initialStateVehicleType = {
    types: [],
    loading: false,
    errorMessage: null,
};

export const VehicleTypeReducer = (state = initialStateVehicleType, action) => {
    switch (action.type) {
        case 'REQUEST_GET_VEHICLE_TYPES':
            return {
                ...state,
                loading: true,
            };
        case 'GET_VEHICLE_TYPES_SUCCESS':
            return {
                ...state,
                types: action.payload,
                loading: false,
            };
        case 'GET_VEHICLE_TYPES_ERROR':
            return {
                ...state,
                loading: false,
                errorMessage: action.error,
            };
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};
