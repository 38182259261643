import { Service } from './baseService';
import service from './baseService';
import { getEnterpriseId } from '../config';

export const loginService = (credentials) => {
    return Service.post('/driver-auth/login', credentials, {
        params: { enterpriseId: getEnterpriseId() },
    });
};

export const getDriverService = (data) => {
    return Service.post('/driver-auth/get-driver', data, {
        params: { enterpriseId: getEnterpriseId() },
    });
};

export const updateDriverService = (data, driverId) => {
    return Service.put(`/driver-auth/update-driver/${driverId}`, data);
};

export const checkPassword = (data, id) => {
    return Service.post(`/driver-auth/check-password/${id}`, { data });
};

export const sendForgetPasswordEmailService = (payload) => {
    return Service.post('/driver-auth/forgot-password-mail', payload);
};

export const logoService = (companyID) => {
    return service.get('/user/logo', { params: { companyId: companyID } });
};

export const loginImageService = () => {
    return Service.get('/auth/login-image');
};
