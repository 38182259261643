import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Button, ButtonGroup, Card } from 'react-bootstrap';
import SimpleTable from '../../../../Components/SimpleTable';
import { useDispatchState, useAccountState, getTripSettings } from '../../../../Context';
import {
    getAllDispatch,
    filterDispatchesByType,
    updateDispatchDetails,
    getAllDispatchEvents,
    storeFilters,
} from '../../../../Context/actions/dispatchActions';
import FilterDropdown from '../../../../Components/FilterDropDown/FilterDropdown';
import './index.scss';
import Dialog from '../../../../Components/Dialog';
import ListingLayout from '../../../../Components/ListingLayout/ListingLayout';
import { DatePicker } from 'react-tempusdominus-bootstrap';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import moment from 'moment';

const Dispatches = (props) => {
    const {
        dispatchDispatch,
        dispatch: {
            dispatches,
            dispatchesCopy,
            DriverAbsenceEventList,
            DriverAbsenceEvents,
            otherAssignmentEvents,
            otherAssignmentEventList,
            date,
            storedFilters,
        },
    } = useDispatchState();
    const [filterYards, setFilterYards] = useState(storedFilters?.filterYards?.length ? storedFilters.filterYards : []);
    const [filterVehicleType, setFilterVehicleTypes] = useState(
        storedFilters?.filterVehicleType?.length ? storedFilters.filterVehicleType : []
    );
    const {
        AccountsDispatch,
        tripSettings: { tripSettings },
    } = useAccountState();
    const [dispatchData, setDispatchData] = useState({
        showModal: false,
        showAcceptModal: false,
        item: {},
        status: '',
    });
    const [loading, setLoading] = useState(false);
    const [updatedDispatches, setUpdatedDispatches] = useState([]);
    const [dispatchEvents, setDispatchEvents] = useState([]);
    const [warningDispatches, setWarningDispatches] = useState([]);
    const calendarRef = useRef();
    const initialDate = date ? date : new Date();

    useEffect(() => {
        (async () => {
            setLoading(true);
            let accSetting = await getTripSettings(AccountsDispatch);
            const root = document.documentElement;
            root?.style.setProperty('--background-color-custom', accSetting.sectionColor);
            root?.style.setProperty('--background-color-button', accSetting.buttonColor);
            const dispatches = await getAllDispatch(dispatchDispatch, date ? date : moment());
            const dispatchesWithoutStartOrEnd = dispatches?.allDispatches?.filter(
                (d) => !d.driverStartTime || !d.driverEndTime
            );
            if (dispatchesWithoutStartOrEnd?.length) setWarningDispatches(dispatchesWithoutStartOrEnd);

            const { allDispatches } = await getAllDispatchEvents(dispatchDispatch);

            // calendar requires each event to have a unique ID
            setDispatchEvents(
                allDispatches.map((dispatch, i) => ({
                    ...dispatch,
                    id: dispatch.id + '-' + i,
                }))
            );

            setLoading(false);
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatchDispatch]);

    const checkTime = useCallback(() => {
        let updatedDispatch = dispatches
            .filter((d) => d.driverStartTime && d.driverEndTime)
            .map((item) => {
                let difference = moment(item.driverStartTime).diff(moment(), 'minutes');
                if (!item.checkInButtonDisableMin) {
                    return { ...item, isButtonDisabled: false };
                } else if (difference < Number(item.checkInButtonDisableMin)) {
                    return { ...item, isButtonDisabled: false };
                } else {
                    return { ...item, isButtonDisabled: true };
                }
            });
        setUpdatedDispatches(updatedDispatch);
    }, [dispatches]);

    // let updatedOtherAssignments = OtherAssignments.map();

    useEffect(() => {
        checkTime();
        const interval = setInterval(checkTime, 60000);
        return () => {
            clearInterval(interval);
        };
    }, [checkTime, dispatches]);

    useEffect(() => {
        storeFilters(dispatchDispatch, { filterVehicleType, filterYards });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterVehicleType, filterYards]);
    const onDateChange = async (newDate = '', direction = 'none') => {
        setLoading(true);
        let date = moment(newDate ? newDate : new Date());
        if (direction !== 'none') {
            if (direction === 'left') date = date.subtract(1, 'days');
            else date = date.add(1, 'days');
        }
        await getAllDispatch(dispatchDispatch, date);
        let calendarApi = calendarRef.current.getApi();
        calendarApi.gotoDate(date.toISOString());

        // filterDispatchesByType(dispatchesDispatch, fetchedDispatches, yards, vehicleTypes, date.toDate());
        setLoading(false);
    };

    let baseOfOperations = [];
    if (!loading && dispatchesCopy.length > 0) {
        baseOfOperations = dispatchesCopy.reduce((types, dispatch) => {
            if (dispatch.baseOfOperation && !types.includes(dispatch.baseOfOperation)) {
                types.push(dispatch.baseOfOperation);
            }
            return types;
        }, []);
    }

    let reducedVehicleTypes = [];
    if (!loading && dispatchesCopy.length > 0) {
        reducedVehicleTypes = dispatchesCopy.reduce((types, dispatch) => {
            if (dispatch.vehicleDisplayName && !types.includes(dispatch.vehicleDisplayName)) {
                types.push(dispatch.vehicleDisplayName);
            }
            return types;
        }, []);
    }

    const handleFilterYardsChanged = (type) => {
        let newFilterTypes;
        if (filterYards.includes(type)) {
            newFilterTypes = filterYards.filter((fType) => fType !== type);
        } else {
            newFilterTypes = [...filterYards, type];
        }
        setFilterYards(newFilterTypes);
        filterDispatchesByType(dispatchDispatch, dispatchesCopy, newFilterTypes, filterVehicleType, date);
    };

    const handleVehicleTypeChanged = (type) => {
        let newFilterTypes;
        if (filterVehicleType.includes(type)) {
            newFilterTypes = filterVehicleType.filter((fType) => fType !== type);
        } else {
            newFilterTypes = [...filterVehicleType, type];
        }
        setFilterVehicleTypes(newFilterTypes);
        filterDispatchesByType(dispatchDispatch, dispatchesCopy, filterYards, newFilterTypes, date);
    };

    const resetFilters = () => {
        setFilterYards([]);
        setFilterVehicleTypes([]);
        filterDispatchesByType(dispatchDispatch, dispatchesCopy, [], [], null);
    };

    const onRowClick = (item) => {
        // if(editingRow === undefined || editingRow === "") {
        props.history.push(`/driver/assignments/${item.id}`);
        // }
    };

    const updateDriverConfirmed = async (e, item, confirmed) => {
        // confirmed is what user clicks on confirm dispatch(1) / decline dispatch (0)
        e.stopPropagation();
        let updateDispatchData = {};
        if (confirmed === 0) {
            // declined
            updateDispatchData = {
                driverDeclinedAt: new Date(),
            };
        } else {
            // accepted
            updateDispatchData = {
                driverAcceptedAt: new Date(),
            };
        }
        await updateDispatchDetails(dispatchDispatch, item.id, updateDispatchData);
    };

    const handleCheck = async (e, item, type) => {
        e.stopPropagation();
        let obj = {};
        if (type === 'checkout') {
            obj = {
                driverCheckOut: new Date(),
                driverPortalCheckOutDriverID: item.relatedDriver,
            };
        } else {
            obj = {
                driverCheckIn: new Date(),
                driverPortalCheckInDriverID: item.relatedDriver,
            };
        }
        await updateDispatchDetails(dispatchDispatch, item.id, obj);
    };

    const checkInOutRenderer = (item) => {
        if (item.driverCheckIn && !item.useDriverCheckoutTime)
            return <i className="fas fa-check-circle text-dark-blue" />;
        else if (item.driverCheckIn && item.driverCheckOut && item.useDriverCheckoutTime)
            return <i className="fas fa-check-circle text-dark-blue" />;
        else if (item.driverCheckIn && !item.driverCheckOut && item.useDriverCheckoutTime) {
            return (
                <Button
                    className="p-1 w-100"
                    onClick={(e) => handleCheck(e, item, 'checkout')}
                    disabled={item.isButtonDisabled}
                >
                    CHECK OUT
                </Button>
            );
        } else {
            return (
                <Button
                    className="p-1 w-100"
                    disabled={item.isButtonDisabled}
                    onClick={(e) => handleCheck(e, item, 'checkin')}
                >
                    CHECK IN
                </Button>
            );
        }
    };

    const cancelUpdate = () => {
        setDispatchData({
            showModal: false,
            showAcceptModal: false,
            item: {},
            status: '',
        });
    };
    const continueUpdate = (e) => {
        const { status, item } = dispatchData;
        updateDriverConfirmed(e, item, status);
        setDispatchData({
            showModal: false,
            showAcceptModal: false,
            item: {},
            status: '',
        });
    };
    const acceptClickHandler = (e, item) => {
        e.stopPropagation();
        setDispatchData({
            showModal: false,
            showAcceptModal: true,
            status: 1,
            item: item,
        });
    };
    const declinedClickHandler = (e, item) => {
        e.stopPropagation();
        setDispatchData({
            showAcceptModal: false,
            showModal: true,
            status: 0,
            item: item,
        });
    };
    const tableFilters = (
        <>
            <div className="d-flex align-items-center col">
                <FilterDropdown
                    title="Base Of Operation"
                    options={baseOfOperations.map((yard) => ({
                        title: yard,
                        value: yard,
                    }))}
                    filterValues={filterYards}
                    changeHandler={(option) => handleFilterYardsChanged(option.value)}
                    dropdownId="operation"
                />
                <FilterDropdown
                    title="Vehicle Type"
                    options={reducedVehicleTypes.map((vehicleType) => ({
                        title: vehicleType,
                        value: vehicleType,
                    }))}
                    filterValues={filterVehicleType}
                    changeHandler={(option) => handleVehicleTypeChanged(option.value)}
                    dropdownId="vehicle"
                />
            </div>
            <div className="col text-right justify-content-end d-flex">
                <Button className="btn btn-primary" onClick={() => resetFilters()}>
                    <i className="fas fa-sync-alt" /> Clear Filters
                </Button>
            </div>
        </>
    );
    const headerActions = (
        <div className="date-filter">
            <div className="date-filter-input">
                <span onClick={() => onDateChange(date, 'left')}>
                    <i className="fas fa-caret-left" />
                </span>
                <label className="test">
                    <DatePicker
                        date={date ? date : moment().format('MM/DD/YYYY')}
                        defaultDate={date ? date : moment().format('MM/DD/YYYY')}
                        onChange={(date) => {
                            onDateChange(date.date);
                        }}
                    />
                </label>
                <span onClick={() => onDateChange(date, 'right')}>
                    <i className="fas fa-caret-right" />
                </span>
            </div>
        </div>
    );

    const renderDriverConfirmed = (item) => {
        let content = (
            <ButtonGroup>
                <Button
                    variant="success"
                    onClick={(e) => acceptClickHandler(e, item)}
                    className="bg-success text-white"
                >
                    <i className="fas fa-check-circle" />
                </Button>
                <Button
                    variant="danger"
                    onClick={(e) => declinedClickHandler(e, item)}
                    className="bg-danger text-white"
                >
                    <i className="fas fa-times-circle" />
                </Button>
            </ButtonGroup>
        );
        if (Number(item.hideDriverPortalDeclineButton))
            content = (
                <ButtonGroup>
                    <Button
                        variant="success"
                        onClick={(e) => acceptClickHandler(e, item)}
                        className="bg-success text-white"
                    >
                        <i className="fas fa-check-circle" />
                    </Button>
                </ButtonGroup>
            );
        if (item.driverAcceptedAt) {
            content = (
                <Button variant="success" disabled={true} className="bg-success text-white">
                    <i className="fas fa-check-circle" />
                </Button>
            );
        }
        if (item.driverDeclinedAt) {
            content = (
                <Button variant="danger" disabled={true} className="bg-danger text-white">
                    <i className="fas fa-times-circle" />
                </Button>
            );
        }
        return content;
    };

    const handleEventClick = async (e) => {
        let calendarApi = calendarRef.current.getApi();
        // let date = moment(e.event?._def?.extendedProps?.formateStart)
        let date = moment(e.event?.start);
        calendarApi.gotoDate(date.toISOString());
        // eslint-disable-next-line no-const-assign
        // date = moment(date);
        window.scrollTo(0, 0);
        setLoading(true);
        await getAllDispatch(dispatchDispatch, date);
        setLoading(false);
        // props.history.push(`/driver/assignments/${e.event?._def?.publicId}`)
    };

    const fieldNames = [
        ['company', 'Company'],
        ['parentCompany', 'Parent Company'],
        [
            'bookingFormattedID',
            'Booking ID',
            (item) =>
                item &&
                (item.bookingId && item.bookingFormattedID
                    ? item.bookingFormattedID
                    : item.description
                    ? item.description
                    : ''),
        ],
        ['operationalContactName', 'Contact Name'],
        ['operationalContactOrganization', 'Organization'],
        ['vehicleDisplayName', 'Vehicle Type'],
        ['vehicleID', 'Vehicle ID'],
        ['driverStartTime', 'Driver Start Time'],
        ['firstDepartureTime', 'First Pick-Up Time'],
        ['firstPickupLocation', 'First Pick-Up Location'],
        ['lastArrivalTime', 'Last Drop-Off Time'],
        ['driverEndTime', 'Driver End Time'],
        ['baseOfOperation', 'Base of Operation'],
        ['driverConfirmed', 'Accepted', renderDriverConfirmed],
    ];

    if (tripSettings.showDriverPortalCheckInButton) fieldNames.unshift(['actualEndTime', '', checkInOutRenderer]);

    const absence_fieldNames = [
        ['absenceType', 'Absence Type'],
        ['date', 'Start Date'],
        ['date', 'End Date'],
    ];

    const otherAssignment_fieldNames = [
        ['assignmentType', 'Assignment Type'],
        ['scheduledStartTime', 'Start Date'],
        ['scheduledEndTime', 'End Date'],
    ];

    const warningDispatches_fieldNames = [
        ['company', 'Company'],
        ['bookingFormattedID', 'Booking ID'],
        ['firstDepartureTime', 'First Departure Time'],
    ];

    return (
        <>
            {warningDispatches?.length ? (
                <ListingLayout
                    title={'Dispatches Without Driver Start or End Time'}
                    titleIconClass={'fa fa-exclamation-circle'}
                    textColor="#dc3545"
                    listingTable={
                        <>
                            <p className="text-danger bold">
                                You are assigned to the following dispatch(es) which do not have a driver start or end
                                time set. Please contact your dispatcher.
                            </p>
                            <SimpleTable
                                loading={loading}
                                fieldNames={warningDispatches_fieldNames}
                                mainColor={tripSettings?.mainColor}
                                data={warningDispatches}
                                showFooter={true}
                                bordered={true}
                            />
                        </>
                    }
                ></ListingLayout>
            ) : (
                ''
            )}
            <ListingLayout
                title={'Assignments'}
                titleIconClass={'fa fa-paper-plane'}
                headerActions={headerActions}
                textColor={tripSettings?.textColor}
                cardHeader={tableFilters}
                listingTable={
                    <SimpleTable
                        onClick={onRowClick}
                        loading={loading}
                        fieldNames={fieldNames}
                        data={updatedDispatches}
                        mainColor={tripSettings?.sectionColor}
                        showFooter={true}
                        bordered={true}
                    />
                }
            >
                {dispatchData.showModal ? (
                    <Dialog
                        title={'Confirm Dispatch Decline'}
                        message={'Are you sure you wish to decline this dispatch assignment?'}
                        displayOkButton
                        okButtonText="YES"
                        okButtonClick={continueUpdate}
                        displayCancelButton
                        cancelButtonText="NO"
                        cancelButtonClick={cancelUpdate}
                        onCloseClick={cancelUpdate}
                    />
                ) : null}
                {dispatchData.showAcceptModal ? (
                    <Dialog
                        title={'Confirm Dispatch Acceptance'}
                        message={'Are you sure you wish to accept this dispatch assignment?'}
                        displayOkButton
                        okButtonText="YES"
                        okButtonClick={continueUpdate}
                        displayCancelButton
                        cancelButtonText="NO"
                        cancelButtonClick={cancelUpdate}
                        onCloseClick={cancelUpdate}
                    />
                ) : null}
            </ListingLayout>

            {otherAssignmentEventList?.length ? (
                <ListingLayout
                    title={'Other Assignments'}
                    titleIconClass={'fa fa-paper-plane'}
                    textColor={tripSettings?.textColor}
                    listingTable={
                        <SimpleTable
                            loading={loading}
                            fieldNames={otherAssignment_fieldNames}
                            mainColor={tripSettings?.sectionColor}
                            data={otherAssignmentEventList}
                            showFooter={true}
                            bordered={true}
                        />
                    }
                ></ListingLayout>
            ) : (
                ''
            )}

            {DriverAbsenceEventList.length ? (
                <ListingLayout
                    title={'Driver Absence Events'}
                    titleIconClass={'fa fa-paper-plane'}
                    textColor={tripSettings?.textColor}
                    listingTable={
                        <SimpleTable
                            loading={loading}
                            fieldNames={absence_fieldNames}
                            mainColor={tripSettings?.mainColor}
                            data={DriverAbsenceEventList}
                            showFooter={true}
                            bordered={true}
                        />
                    }
                ></ListingLayout>
            ) : (
                ''
            )}

            <ListingLayout
                titleIconClass="fa fa-calendar-alt"
                title="Schedule Calendar"
                textColor={tripSettings?.textColor}
                heightAuto
                middleContent={
                    <>
                        <Card className="m-3">
                            <div className="event-container">
                                <div xs={6} lg={6} md={4}>
                                    <div className="event-type">
                                        <div className="green-div-box">
                                            <div>
                                                <p />
                                            </div>
                                        </div>
                                        <div className="green-div-title">
                                            <span>Accepted Assignment </span>
                                        </div>
                                    </div>
                                </div>
                                <div xs={6} lg={6} md={4}>
                                    <div className="event-type">
                                        <div className="yellow-div-box">
                                            <div>
                                                <p />
                                            </div>
                                        </div>
                                        <div className="yellow-div-title">
                                            <span>Assignment Scheduled; Requires Action </span>
                                        </div>
                                    </div>
                                </div>
                                <div xs={6} lg={6} md={4}>
                                    <div className="event-type">
                                        <div className="purple-div-box">
                                            <div>
                                                <p />
                                            </div>
                                        </div>
                                        <div className="purple-div-title">
                                            <span>Other Assignment </span>
                                        </div>
                                    </div>
                                </div>
                                {/* <div xs={6} lg={6} md={4}>
                                <div className='event-type'>
                                    <div className="blue-div-box">
                                        <div><p /></div>
                                    </div>
                                    <div className="blue-div-title">
                                        <span>Scheduled; Assignment Pending </span>
                                    </div>
                                </div>
                            </div> */}
                                <div xs={6} lg={6} md={4}>
                                    <div className="event-type">
                                        <div className="red-div-box">
                                            <div>
                                                <p />
                                            </div>
                                        </div>
                                        <div className="red-div-title">
                                            <span>Declined Assignment </span>
                                        </div>
                                    </div>
                                </div>
                                <div xs={6} lg={6} md={4}>
                                    <div className="event-type">
                                        <div className="grey-div-box">
                                            <div>
                                                <p />
                                            </div>
                                        </div>
                                        <div className="grey-div-title">
                                            <span>Absence Events </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </>
                }
                listingTable={
                    <div className="calendar-wrapper">
                        <FullCalendar
                            ref={calendarRef}
                            plugins={[dayGridPlugin, interactionPlugin]}
                            initialView="dayGridMonth"
                            headerToolbar={{
                                left: 'prev',
                                center: 'title',
                                right: 'next',
                            }}
                            initialDate={initialDate}
                            events={[...dispatchEvents, ...otherAssignmentEvents, ...DriverAbsenceEvents]}
                            eventClick={(e) => handleEventClick(e)}
                            dayHeaderFormat={{ weekday: 'long' }}
                            eventContent={() => {
                                //return info.event?._def?.extendedProps?.routeDescription
                            }}
                        />
                    </div>
                }
            ></ListingLayout>
        </>
    );
};

export default Dispatches;
